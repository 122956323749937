import React from 'react'
import opcion3 from "../../assets/images/llantas/llanta_rovelo_rhp780p_5_7.jpg";
import marca3 from "../../assets/images/marcas/rovelo.png";
import miniatura from "../../assets/images/llantas/llanta_rovelo_rhp780p_5_7-miniatura.jpg"
import { TbInfoCircleFilled } from 'react-icons/tb';
import { MetaTags } from 'react-meta-tags';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Sunfull from '../../assets/images/marcas/Sunfull - Sin fondo.png'
import FullRun from '../../assets/images/marcas/fullrun.png'
import BlackHawk from '../../assets/images/marcas/blackhawk.png'
import Wanli from '../../assets/images/marcas/Wanli - Sin fondo.png'
import Rovelo from '../../assets/images/marcas/rovelo.png'
import Troque from '../../assets/images/marcas/Troque - Sin fondo.png'
import Sunny from '../../assets/images/marcas/Sunny - Sin fondo.png'
import Hevos from '../../assets/images/marcas/Hevos - Sin fondo.png'
import Greforce from '../../assets/images/marcas/Greforce - Sin fondo.png'
import GoldenUnicorn from '../../assets/images/marcas/Golden unicorn - Sin fondo.png'
import CashLand from '../../assets/images/marcas/Cachland - Sin fondo.png'
import WindForce from '../../assets/images/marcas/windforce.png'
import ButtonVolver from '../../components/ButtonVolver';

const ProductosDetails = () => {
  const { state } = useLocation();
  const llantaSeleccionada = state ? state.producto : null;
  const imagenUrl = state ? state.imageUrl : null;

  const formatPrecio = (precio) => {
    return precio.toLocaleString('es-ES')
  }

  const detallesMostrar = [
    "utqg",
    "lonas",
    "diseño",
    "presion_maxima",
    "indice_carga",
    "indice_velocidad",
    "construccion",
    "ancho_seccion",
    "capacidad_carga_maxima",
    "diametro_total",
    "presion_maxima",
    "sin_neumatico"
  ];

  const marcasImagenes = {
    "SUNFULL": Sunfull,
    "WANLI": Wanli,
    "BLACKHAWK": BlackHawk,
    "SUNNY": Sunny,
    "FULLRUNN": FullRun,
    "GREFORCE": Greforce,
    "ROVELO": Rovelo,
    "TROQUE": Troque,
    "HEVOS": Hevos,
    "GOLDEN UNICORN": GoldenUnicorn,
    "CASHLAND": CashLand,
    "WINDFORCE": WindForce
  };

  const marcaImagen = marcasImagenes[llantaSeleccionada.marca.toUpperCase()] || null;

  return (
    <main className="container_productos_true_contact">
      <MetaTags>
        <title>TrueContact | Detalles del Producto</title>
      </MetaTags>
      <div style={{ paddingTop:"4rem", display: "flex", alignItems: "center", gap: ".5rem" }}>
        <ButtonVolver />
        <h1 style={{ paddingLeft: "5px" }} className="title_productos">
          Detalles del Producto
        </h1>
      </div>
      <section className="contain_producto_detail">
        <div className="container_llanta">
          <div className="content_llanta">
            <div className="content_imagenes_detalles_llanta">
              <div className="content_imagen_miniatura">
                <img
                  className="imagen_miniatura"
                  src={imagenUrl}
                  alt={llantaSeleccionada.name}
                />
              </div>
              <div className="container_img_modelo">
                <div className="content_img_medida">
                  <img className="img_medida" src={imagenUrl} alt="" />
                </div>
                <div className="content_img_marca">
                  <img className="img_marca" src={marcaImagen} alt="" />
                </div>
              </div>
            </div>
            <article className="info_llanta">
              <div className="">
                <div className="content_name">
                  <div>
                    <h2 className="name_llanta">
                      {llantaSeleccionada.name.toUpperCase()}
                    </h2>
                  </div>
                  <h4 className="name_medida">
                    Medida {llantaSeleccionada.referencia}
                  </h4>
                </div>
                {llantaSeleccionada.detalles_producto &&
                llantaSeleccionada.detalles_producto.length > 0 ? (
                  llantaSeleccionada.detalles_producto.map((detalle, index) => (
                    <div className="" key={index}>
                      {detallesMostrar.map(
                        (clave) =>
                          detalle[clave] && (
                            <p
                              key={clave}
                              className="info_llantas_caracteristicas"
                            >
                              <span className="span_llanta">
                                {clave.replace(/_/g, " ").toUpperCase()}
                              </span>
                              :{" "}
                              {detalle[clave] !== null
                                ? detalle[clave]
                                : "No disponible"}
                            </p>
                          )
                      )}
                    </div>
                  ))
                ) : (
                  <p>No hay detalles disponibles</p>
                )}
                {/* {llantaSeleccionada.caracteristicas.map((obj) => (
                  <p key={obj.valor} className="info_llantas_caracteristicas">
                    <span className="span_llanta">{obj.clave}</span>:{" "}
                    {obj.valor} <TbInfoCircleFilled />
                  </p>
                ))} */}
              </div>
            </article>

            <article className="content_precios">
              <div className="precios">
                <p className="precio_nuevo_market">
                  ${formatPrecio(parseFloat(llantaSeleccionada.precio))}{" "}
                  <span className="cada_uno">c/u</span>
                </p>
              </div>

              <div className="content_buttons_market">
                <div className="container_buttons_cantidad">
                  <div>
                    <span className="cantidad">
                      +{llantaSeleccionada.cantidad - 5} DISPONIBLES
                    </span>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ProductosDetails