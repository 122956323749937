import React from 'react';
import { useCart } from '../../ProductosLlantas/components/CartContext';
import visa from "../../../assets/images/visa.png";
import wompi from "../../../assets/images/logo-wompi.svg";
import { TbTrash } from 'react-icons/tb';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Llanta from '../../../assets/images/llantas/neumatico-albacete-vector.png'

const CardCarrito = () => {
  const { cart, removeFromCart, incrementQuantity, decrementQuantity } = useCart();
  const totalPagar = cart.reduce((total, item) => total + item.stock * Number(item.precio), 0);
  const cantidadProductos = cart.reduce((cantidad, item) => cantidad + item.stock, 0);
  console.log(cart)
  const formatPrecio = (precio) => {
    if (typeof precio !== 'number' || isNaN(precio)) {
      return "0";
    }
    return precio.toLocaleString('es-ES');
  };

  const BASE_URL = process.env.REACT_APP_API_URL;

  const getImageUrl = (imagePath) => {
    return imagePath ? `${BASE_URL.replace("/api", "")}${imagePath}` : Llanta;
  };

  const history = useHistory();

  const redirect = (path) => {
    history.push(`${path}`)
  };

  const detallesMostrar = [
    "utqg",
    "lonas",
    "presion_maxima",
    "indice_carga",
    "indice_velocidad",
    "ancho_seccion",
    "diametro_total",
    "m_s",
    "diseño",
    "construccion",
  ];

  return (
    <div className="carrito">
      <div className="container_carrito">
        <div className="container_productos">
          <h2 className="title_carrito">Mi carrito</h2>
          {cart.length === 0 ? (
            <p className="carrito_vacio">El carrito está vacío.</p>
          ) : (
            cart.map((item) => (
              <div className="content_producto_carrito" key={item.id}>
                <div className="container_img_carrito_">
                  <Link to={`/llanta-${item.name}-${item.id}`}>
                    <img
                      className="img_carrito_"
                      src={getImageUrl(item.imagenes[0]?.imagen)}
                      alt={item.name}
                    />
                  </Link>
                </div>
                <div className="container_info_carrito">
                  <div>
                    <Link to={`/llanta-${item.name}-${item.id}`}>
                      <h3 className="name_llanta_card">{item.name}</h3>
                    </Link>
                    <h4 className="medida_producto_carrito">{item.referencia}</h4>
                    <div className='content_card-carrito'>
                      <div className=" card-carrito">
                        {item.detalles_producto &&
                        item.detalles_producto.length > 0 ? (
                          item.detalles_producto.map((detalle, index) => (
                            <div key={index}>
                              {[
                                "utqg",
                                "lonas",
                                "indice_carga",
                                "indice_velocidad",
                                "m_s",
                                "presion_maxima",
                                "diseño",
                                "construccion",
                              ].map(
                                (clave) =>
                                  detalle[clave] && (
                                    <p
                                      key={clave}
                                      className="caracteristicas_llanta_carrito"
                                    >
                                      <span className="span_llanta">
                                        {clave.replace(/_/g, " ").toUpperCase()}
                                      </span>
                                      :{" "}
                                      {detalle[clave] !== null
                                        ? detalle[clave]
                                        : "No disponible"}
                                    </p>
                                  )
                              )}
                            </div>
                          ))
                        ) : (
                          <p>No hay detalles disponibles</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="content_actions_carrito">
                    <div className="content_buttons_market">
                      <div className="content_buttons_cantidad">
                        <button
                          className="buttons_cantidad_carrito left"
                          onClick={() => decrementQuantity(item.id)}
                        >
                          -
                        </button>
                        <input
                          className="input_cantidad_carrito"
                          type="text"
                          value={item.stock}
                          readOnly
                        />
                        <button
                          className="buttons_cantidad_carrito right"
                          onClick={() => incrementQuantity(item.id)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <TbTrash
                      className="button_trash_cart"
                      size={25}
                      title="Borrar de la lista"
                      onClick={() => removeFromCart(item.id)}
                    />
                  </div>
                </div>
                <div>
                  <p className="precio_producto_carrito">
                    ${formatPrecio(parseFloat(item.precio))}
                  </p>
                </div>
              </div>
            ))
          )}
          <div>
            <p
              style={{ textAlign: "end", paddingTop: "10px" }}
              className="subtotal"
            >
              Subtotal ({cantidadProductos} productos):{" "}
              <span className="total_pagar">${formatPrecio(totalPagar)}</span>
            </p>
          </div>
        </div>
        <div className="container_actions">
          <div className="container_pagar">
            <h3 className="title_pagar">Total a pagar </h3>
            <h4 className="subtotal">
              Subtotal ({cantidadProductos} productos):
            </h4>
            <div className="conten_pagar">
              <div className="conten_img_pagar">
                <img className="visa" src={visa} alt="" />
                <img className="wompi" src={wompi} alt="" />
              </div>
              <div>
                <p className="total_pagar">${formatPrecio(totalPagar)}</p>
              </div>
            </div>
            <button
              onClick={() => redirect("/to_buy")}
              className="button_pagar"
            >
              Proceder a pagar
            </button>
          </div>
          <div className="container_recomendaciones">
            <h3 className="title_pagar">Recomendaciones</h3>
            <p className=''>Estamos trabajando en ello.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardCarrito;
