import React from 'react'
import ButtonVolver from '../../../components/ButtonVolver'

const NavBarAddProductos = ({ handleSubmit }) => {
  return (
    <section className="content_title_productos_form">
      <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
        <ButtonVolver />
        <div>
          <span className="title_productos">Creando Producto</span>
        </div>
      </div>
      <div style={{ paddingRight: "1rem" }}>
        <button onClick={handleSubmit} className="button_product_agg">
          Crear
        </button>
      </div>
    </section>
  );
}

export default NavBarAddProductos