/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import CardLlanta from './CardLlanta';
import ErrorBusqueda from '../../Error/ErrorBusqueda';
import { getProductosList, getProductosPorMedida } from '../../../helpers/backend';
import LoadingCarga from '../../Loading/LoadingCarga';

const ListaLlantas = ({ searchProducto = '', listType }) => {
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const loaderRef = useRef(null);

  const getProductos = async (url = '') => {
    setLoading(true);
    console.log('Solicitando productos desde URL:', url); // Ver desde qué URL se está solicitando

    try {
      const response = await getProductosList(url);

      console.log('Respuesta de la API:', response.data); // Ver los datos que llegan de la API

      setParams((prevParams) => {
        // Verificación de cómo se actualizan los resultados
        console.log('Estado previo de params:', prevParams);
        const updatedResults = url === '' 
          ? response.data.results
          : [...prevParams.results, ...response.data.results];
        console.log('Nuevos resultados después de la actualización:', updatedResults);
        return {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: updatedResults,
        };
      });
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  console.log('Estado actual de params:', params);

  const getFilterData = async () => {
    if (searchProducto) {
      setLoading(true);
      console.log('Filtrando productos por medida:', searchProducto);

      try {
        const response = await getProductosPorMedida(searchProducto.replace(/-/g, '/'));
        console.log('Respuesta de los productos filtrados:', response.data);

        setParams({
          count: response.data.length,
          next: null,
          previous: null,
          results: response.data,
        });
      } catch (error) {
        console.error('Error fetching filtered products:', error);
      } finally {
        setLoading(false);
      }
    } else {
      getProductos();
    }
  };

  const filteredLlantas = params.results.filter((llanta) => {
    const normalizedSearch = searchProducto.replace(/-/g, '/').toLowerCase(); // Normalizando el searchProducto
    const normalizedName = llanta.name.replace(/-/g, '/').toLowerCase(); // Normalizando el name de la llanta
    return normalizedName.includes(normalizedSearch); // Compara los valores normalizados
  });

  useEffect(() => {
    console.log('searchProducto ha cambiado:', searchProducto); // Ver si el valor de searchProducto cambia
    setParams({
      count: 0,
      next: null,
      previous: null,
      results: [],
    });
    getFilterData();
  }, [searchProducto]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && params.next && !searchProducto) {
          console.log('Cargando más productos, params.next:', params.next); // Ver qué URL tiene `params.next`
          getProductos(params.next);
        }
      },
      {
        rootMargin: '100px',
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loading, params.next, searchProducto]);

  return (
    <div className={`${listType ? 'llantas_list_layout' : 'llantas_list'}`}>
      {loading && (
        <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
          <LoadingCarga />
        </div>
      )}

      {filteredLlantas.length === 0 && !loading ? (
        <div style={{ width: '1000px' }}>
          <ErrorBusqueda />
        </div>
      ) : (
        filteredLlantas.map((llanta) => (
          <CardLlanta key={llanta.id} llanta={llanta} listType={listType} />
        ))
      )}

      {!searchProducto && params.next && (
        <div ref={loaderRef} style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
          <LoadingCarga />
        </div>
      )}
    </div>
  );
};

export default ListaLlantas;
