import React from 'react'
import ListaLlantas from './ListaLlantas'

const Llantas = ({ listType, medida }) => {
  return (
    <div className="content_list_llantas">
      <ListaLlantas
        listType={listType}
        searchProducto={medida}
      />
    </div>
  );
}

export default Llantas