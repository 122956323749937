import axios from "axios";



const URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
    baseURL: URL,
  })


  export  const get = async (url) => {

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: false,
    };
    const res = await axiosApi.get(url,  config);
    
    return res;
  };

  

  export  const post  = async (url, data = {}) => {

    const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: false,
      };
    const res = await axiosApi.post (url,  data ,config);
    
    return res;
  };

  