import React from 'react';
import { useCart } from '../../ProductosLlantas/components/CartContext';
import { TbTrash } from "react-icons/tb";
import Llanta from '../../../assets/images/llantas/neumatico-albacete-vector.png'


const CardCart = () => {
  const { cart, removeFromCart } = useCart();
  
  const formatPrecio = (precio) => {
    return precio.toLocaleString('es-ES')
  }

  const BASE_URL = process.env.REACT_APP_API_URL;

  const getImageUrl = (imagePath) => {
    return imagePath ? `${BASE_URL.replace("/api", "")}${imagePath}` : Llanta;
  };


  return (
    <div className="card_cart">
      {cart.length === 0 ? (
        <p className="carrito_vacio">El carrito está vacío.</p>
      ) : (
        <div>
          {cart.map((item) => (
            <div className="content_info_producto_cart" key={item.id}>
              <div className="content_img_cart">
                <img
                  className="img_cart"
                  src={getImageUrl(item.imagenes[0]?.imagen)}
                  alt={item.name}
                />
              </div>
              <div className="info_producto">
                <h3 className="title_producto">{item.name}</h3>
                <p className="medida_cart">{item.medida}</p>
              </div>
              <div className="conten_cantidad_precio">
                <p className="cantidad_cart">
                  Cantidad: <span>{item.stock}</span>
                </p>
                <p className="precio_producto">
                  <span className="dolar">$</span>
                  {formatPrecio(parseFloat(item.precio))}{" "}
                  <span className="cada_uno_cart">c/u</span>
                </p>
              </div>
              <div className="content_button_trash">
                <TbTrash
                  className="button_trash_cart"
                  size={25}
                  title="Borrar de la lista"
                  onClick={() => removeFromCart(item.id)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CardCart;
