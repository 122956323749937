import React from 'react'
import { TbArrowNarrowLeft } from "react-icons/tb";

const ButtonVolver = () => {

  const handleBack = () => {
    if (typeof window !== 'undefined' && window.history) {
      window.history.back();
    } else {
      console.error("No se pudo acceder a window.history");
    }
  };
  
  return (
    <div style={{ position: "relative" }}>
      <button onClick={handleBack} className="button_regresar">
        <TbArrowNarrowLeft size={21} />
      </button>
    </div>
  );
}

export default ButtonVolver