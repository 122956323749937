import React, { useState } from 'react'
import MetaTags from "react-meta-tags";

import './styles/Productos.css'
import NavbarProductos from './components/NavbarProductos';
import HeaderProductos from './components/HeaderProductos';
import ListaProductos from './components/ListaProductos';

const Productos = () => {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshFiltro, setRefreshFiltro] = useState(false)
  const [searchProductos, setSearchProductos] = useState('');
  const [marca, setMarca] = useState('');
  const [categoria, setCategoria] = useState('');
  const [medida, setMedida] = useState('');
  const [rin, setRin] = useState('');

  const handleSearchProductos = (e) => {
    setSearchProductos(e.target.value);
  }
  
  const handleFiltroChange = (filtro, valor) => {
    if (filtro === 'marca') {
      setMarca(valor);
    } else if (filtro === 'categoria') {
      setCategoria(valor);
    } else if (filtro === 'medida') {
      setMedida(valor);
    } else if (filtro === 'rin') {
      setRin(valor);
    }
    setRefreshFiltro(true)
  }

  const actualizarProductos = () => {
    setLoading(true)
    setTimeout(() => {
      setRefresh(true)
      setLoading(false);
    }, 2000);
  }
  
  return (
    <main
      style={{ padding: "5rem 2rem" }}
      className="container_productos_true_contact"
    >
      <MetaTags>
        <title>TrueContact | Productos</title>
      </MetaTags>
      <div>
        <NavbarProductos actualizarProductos={actualizarProductos} />
      </div>
      <div>
        <HeaderProductos
          searchProductos={searchProductos}
          onSearchChange={handleSearchProductos}
          onFiltroChange={handleFiltroChange}
          categoria={categoria}
          medida={medida}
          rin={rin}
          marca={marca}
        />
      </div>
      <div>
        <ListaProductos
          searchProductos={searchProductos}
          loading={loading}
          setLoading={setLoading}
          refresh={refresh}
          setRefresh={setRefresh}
          categoria={categoria}
          medida={medida}
          rin={rin}
          marca={marca}
          refreshFiltro={refreshFiltro}
          setRefreshFiltro={() => setRefreshFiltro(false)}
        />
      </div>
    </main>
  );
}

export default Productos