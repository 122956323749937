/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TbCamera } from "react-icons/tb";
import { TbChartHistogram } from "react-icons/tb";
import { Link } from "react-router-dom";
import LoadingCarga from "../../Loading/LoadingCarga";
import {
  getProductosFilter,
  getProductosList,
  postProductoImagenes,
} from "../../../helpers/backend";
import Pagination from "../../../components/Common/Pagination_ansync";
import Llanta from "../../../assets/images/llantas/neumatico-albacete-vector.png";
import Swal from "sweetalert2";
import LoadingCargaFullScreen from "../../Loading/LoadingCargaFullScreen";

const ListaProductos = ({
  searchProductos,
  loading,
  setLoading,
  refresh,
  setRefresh,
  categoria,
  medida,
  rin,
  marca,
  refreshFiltro,
  setRefreshFiltro,
}) => {
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [file, setFile] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [previewImage, setPreviewImage] = useState([]);
  const [loadingEnvio, setLoadingEnvio] = useState(false);

  const BASE_URL = process.env.REACT_APP_API_URL;

  // FUNCIONES
  const getProductos = async () => {
    const response = await getProductosList();
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });

    setLoading(false);
  };

  const getFilterDataFiltros = async () => {
    setLoading(true);
    const response = await getProductosFilter(
      `?categoría=${categoria}&marca=${marca}&referencia=${medida}&rin=${rin}`
    );

    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setRefreshFiltro();
    setLoading(false);
  };

  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getProductosFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getProductos();
  }, []);

  useEffect(() => {
    if (refresh) {
      getProductos();
    }
  }, [refresh]);

  useEffect(() => {
    if (refreshFiltro) {
      getFilterDataFiltros();
    }
  }, [refreshFiltro]);

  const filteredLlantas = Array.isArray(params.results)
    ? params.results.filter((producto) =>
        producto.name.toLowerCase().includes(searchProductos.toLowerCase())
      )
    : [];

  const handleAlert = (icon, title, text) => {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      timer: 3000,
    });
  };

  // SUBIR IMAGENES

  const handleClickAddImages = (e) => {
    if (selectedProduct.length === 0) {
      handleAlert(
        "warning",
        "¡Atención!",
        "Por favor, seleccione al menos un vehículo."
      );
      e.preventDefault();
    }
  };

  const handleCheck = (vehiculoId) => {
    setSelectedProduct((prevState) => {
      if (prevState.includes(vehiculoId)) {
        return prevState.filter((id) => id !== vehiculoId);
      } else {
        return [...prevState, vehiculoId];
      }
    });
  };

  const handleImagenChange = (e) => {
    const archivos = Array.from(e.target.files);
    if (archivos.length > 0) {
      setFile(archivos);
      const previews = archivos.map((archivo) => URL.createObjectURL(archivo));
      setPreviewImage(previews);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFile([]);
    setPreviewImage([]);
  };

  const handleConfirmImages = async () => {
    if (file.length === 0) {
      console.error("Debe seleccionar al menos una imagen.");
      return;
    }
    const base64Images = await Promise.all(
      file.map((files) => convertToBase64(files))
    );
    await enviarImagenes(base64Images);
  };

  const convertToBase64 = (files) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(files);
    });
  };

  // Enviar imágenes y productos seleccionados al backend
  const enviarImagenes = async (base64Images) => {
    if (selectedProduct.length === 0) {
      console.error("Debe seleccionar al menos un producto.");
      return;
    }
    setShowModal(false);
    setLoadingEnvio(true);
    const data = {
      productos: selectedProduct,
      imagenes: base64Images.map((imagen) => ({ imagen })),
    };

    try {
      const response = await postProductoImagenes(data);
      console.log("Respuesta del servidor:", response);
      if (response.status === 200 || response.status === 201) {
        setTimeout(() => {
          setLoadingEnvio(false);
          handleAlert(
            "success",
            "¡Bien hecho!",
            "Las imágenes se subieron correctamente."
          );
        }, 1500);
        getProductos();
        actualizarProductos();
      } else {
        setLoadingEnvio(false);
        handleAlert(
          "error",
          "Error al subir las imágenes",
          response.data?.message || "Ocurrió un error inesperado."
        );
      }
    } catch (error) {
      console.error("Error al enviar las imágenes:", error);
      handleAlert(
        "error",
        "Error al cargar las imágenes",
        "Hubo un problema al intentar subir las imágenes."
      );
    }
  };

  const actualizarProductos = () => {
    setLoading(true);
    setTimeout(() => {
      setRefresh(true);
      setLoading(false);
    }, 2000);
  };

  return (
    <section className="section_table">
      <table className="tabla_productos" responsive>
        <thead className="thead_lista_productos">
          <tr className="th_lista">
            <th style={{ textAlign: "center" }}>
              <TbCamera size={18} strokeWidth={2.5} />
            </th>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Precio</th>
            <th>Categoría</th>
            <th>Fecha</th>
            <th>Estadística</th>
            <th
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                paddingTop: "15px",
              }}
            >
              <label
                className="label_imagen_producto_vehiculo"
                htmlFor="imagenes_producto"
                title="Subír imágenes"
                onClick={handleClickAddImages}
              >
                <TbCamera size={18} strokeWidth={2.5} />
              </label>
              <input
                className="input_imagen_producto"
                id="imagenes_producto"
                type="file"
                multiple
                onChange={handleImagenChange}
              />
            </th>
          </tr>
        </thead>
        <tbody className="tbody_lista_productos">
          {loading ? (
            <tr>
              <td colSpan="7" style={{ textAlign: "center" }}>
                <LoadingCarga />
              </td>
            </tr>
          ) : filteredLlantas.length > 0 ? (
            filteredLlantas.map((producto) => {
              const imageUrl =
                producto.imagenes && producto.imagenes[0]
                  ? `${BASE_URL.replace("/api", "")}${
                      producto.imagenes[0].imagen
                    }`
                  : Llanta;

              return (
                <tr key={producto.id} className="td_list">
                  <td className="content_img_producto">
                    <img
                      className="img_producto"
                      style={{ paddingTop: ".5rem" }}
                      src={imageUrl}
                      alt={producto.name}
                    />
                  </td>
                  <td style={{ color: "#e0324b" }} className="content_td_name">
                    <Link
                      to={{
                        pathname: `productos/${producto.id}`,
                        state: { producto, imageUrl },
                      }}
                    >
                      <p className="name_td">{producto.name}</p>
                    </Link>
                    <div className="buttons_action_productos">
                      <div>
                        <p className="id_producto">ID: {producto.id}</p>
                      </div>
                      <div className="content_buttons_action">
                        <Link className="action_button" to="#">
                          Editar
                        </Link>{" "}
                        |
                        <Link className="action_button" to="#">
                          Eliminar
                        </Link>{" "}
                        |
                        <Link className="action_button" to="#">
                          Ver
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td>
                    {producto.cantidad > 0 ? (
                      <p className="disponible">Disponible</p>
                    ) : (
                      <p className="no_disponible">No Disponible</p>
                    )}
                  </td>
                  <td>${producto.precio}</td>
                  {producto.categoria > 0 ? (
                    <td className="categoria_">{producto.categoria}</td>
                  ) : (
                    <td className="categoria_">Sin categoría</td>
                  )}
                  <td className="categoria_">
                    Publicado <p className="categoria_">20-10-2023</p>
                  </td>
                  <td>
                    <Link
                      to={{
                        pathname: `/page/${producto.id}/stats`,
                        state: { producto },
                      }}
                      className="icon_estadistica"
                    >
                      <TbChartHistogram size={24} strokeWidth={3} />
                    </Link>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onClick={() => handleCheck(producto.id)}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan="7"
                style={{
                  textAlign: "center",
                  height: "100px",
                  paddingTop: "2.5rem",
                }}
              >
                No hay productos disponibles
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showModal && (
        <section className="modal_llantas">
          <div className="open_modal_llantas">
            <div
              className="modal_content_llantas animation_modal"
              style={{
                width: "400px",
                height: "400px",
                display: "grid",
                alignContent: "center",
                placeContent: "center",
                gap: "3rem",
              }}
            >
              <div className="content_info_imagen_vehiculo">
                <h6 className="title_generar_excel">Imagen seleccionada:</h6>
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="Vista previa"
                    className="preview_img"
                  />
                ) : (
                  <p>No se ha seleccionado ninguna imagen.</p>
                )}
              </div>

              <div className="content_buttons_excel">
                <button
                  className="button_enviar_excel"
                  onClick={handleConfirmImages}
                >
                  Guardar
                </button>
                <button
                  className="button_cancelar_excel"
                  onClick={handleCloseModal}
                >
                  Borrar
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      <div style={{ paddingTop: "2rem" }}>
        <Pagination
          count={params.count}
          next={params.next}
          previous={params.previous}
          limit={12}
          baseUrl="/producto/producto/"
          filterFunction={getFilterData}
        />
      </div>
      {loadingEnvio && <LoadingCargaFullScreen />}
    </section>
  );
};

export default ListaProductos;
