/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  getLineaFilter,
  getMarcaList,
  getVehiculoFilter,
  getVehiculoFiltros,
  getVehiculoList,
  getVersionFilter,
  getYearFilter,
  postVehiculoImagen,
} from "../../helpers/backend";
import { TbSearch } from "react-icons/tb";
import { TbChevronDown } from "react-icons/tb";
import "./styles/AddImagenes.css";
import Swal from "sweetalert2";
import LoadingCarga from "../Loading/LoadingCarga";
import LoadingCargaFullScreen from "../Loading/LoadingCargaFullScreen";
import ImagenNoDisponible from "../../assets/images/vehiculos/imagen-no-disponible.png";
import Pagination from "../../components/Common/Pagination_ansync";
import ButtonVolver from "../../components/ButtonVolver";

const AddImagenes = () => {
  const [loading, setLoading] = useState(false);
  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [searchProductos, setSearchProductos] = useState("");
  const [file, setFile] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [refreshFiltro, setRefreshFiltro] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [previewImage, setPreviewImage] = useState([]);
  const [showFiltro, setShowFiltro] = useState(null);
  const [marcaFiltro, setMarcaFiltro] = useState("");
  const [yearFiltro, setYearFiltro] = useState("");
  const [lineaFiltro, setLineaFiltro] = useState("");
  const [versionFiltro, setVersionFiltro] = useState("");
  const [selectedVersionVehicles, setSelectedVersionVehicles] = useState([]);
  const [selectedMarca, setSelectedMarca] = useState("");
  const [marcaList, setMarcaList] = useState({});
  const [selectedYear, setSelectedYear] = useState("");
  const [yearList, setYearList] = useState({});
  const [selectedLinea, setSelectedLinea] = useState("");
  const [lineaList, setLineaList] = useState({});
  const [selectedVersion, setSelectedVersion] = useState("");
  const [versionList, setVersionList] = useState({});
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const tipoRef = useRef(null);
  const categoriaRef = useRef(null);
  const rinRef = useRef(null);
  const referenciaRef = useRef(null);

  const BASE_URL = process.env.REACT_APP_API_URL;

  /****  Manejadores de Imagenes, Checkbox y Envío de datos  ****/

  const handleCheck = (vehiculoId) => {
    setSelectedProduct((prevState) => {
      if (prevState.includes(vehiculoId)) {
        return prevState.filter((id) => id !== vehiculoId);
      } else {
        return [...prevState, vehiculoId];
      }
    });
  };

  const handleImagenChange = (e) => {
    const archivo = e.target.files[0];
    if (archivo) {
      setFile(archivo);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(archivo);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmImages = async () => {
    if (!file) {
      console.error("Debe seleccionar una imagen.");
      return;
    }
    const base64Image = await convertToBase64(file);
    await enviarImagenes(base64Image);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  // Enviar imágenes y vehículos seleccionados al backend
  const enviarImagenes = async (base64Image) => {
    if (selectedProduct.length === 0) {
      console.error("Debe seleccionar al menos un vehículo.");
      return;
    }
    setShowModal(false);
    setLoadingEnvio(true);
    const data = {
      vehiculos: selectedProduct,
      imagen: base64Image,
    };

    try {
      const response = await postVehiculoImagen(data);
      console.log("Respuesta del servidor:", response);
      if (response.status === 200) {
        setTimeout(() => {
          setLoadingEnvio(false);
          handleAlert(
            "success",
            "¡Bien hecho!",
            "El archivo se subió correctamente."
          );
        }, 1500);
        getVehicle();
        actualizarProductos();
      } else {
        setLoadingEnvio(false);
        handleAlert(
          "error",
          "Error al subir el archivo",
          response.data?.message || "Ocurrió un error inesperado."
        );
      }
    } catch (error) {
      console.error("Error al enviar los archivos:", error);
      handleAlert(
        "error",
        "Error al cargar el archivo",
        "Hubo un problema al intentar subir el archivo."
      );
    }
  };

  const handleAlert = (icon, title, text) => {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      timer: 3000,
    });
  };

  const handleClickAddImages = (e) => {
    if (selectedProduct.length === 0) {
      handleAlert(
        "warning",
        "¡Atención!",
        "Por favor, seleccione al menos un vehículo."
      );
      e.preventDefault();
    }
  };

  const actualizarProductos = () => {
    setLoading(true);
    setTimeout(() => {
      setRefresh(true);
      setLoading(false);
    }, 2000);
  };

  /**************************** */

  /******  Manejadores de FILTROS  *********/

  const handleSearchProductos = (e) => {
    setSearchProductos(e.target.value);
  };

  const handleFiltroChange = (filtro, valor) => {
    if (filtro === "marca") {
      setMarcaFiltro(valor);
    } else if (filtro === "year") {
      setYearFiltro(valor);
    } else if (filtro === "linea") {
      setLineaFiltro(valor);
    } else if (filtro === "version") {
      setVersionFiltro(valor);
    }
    setRefreshFiltro(true);
  };

  const handleOptionSelect = (filtro, value) => {
    handleFiltroChange(filtro, value);
    setShowFiltro(null);
  };

  const handleShowFiltro = (filtro) => {
    setShowFiltro(showFiltro === filtro ? null : filtro);
  };

  const handleClickOutside = (e) => {
    if (
      tipoRef.current &&
      !tipoRef.current.contains(e.target) &&
      categoriaRef.current &&
      !categoriaRef.current.contains(e.target) &&
      rinRef.current &&
      !rinRef.current.contains(e.target) &&
      referenciaRef.current &&
      !referenciaRef.current.contains(e.target)
    ) {
      setShowFiltro(null);
    }
  };

  const handleVersionSelect = (vehiculoId) => {
    setSelectedVersionVehicles((prevState) => {
      if (!prevState.includes(vehiculoId)) {
        return [...prevState, vehiculoId];
      }
      return prevState;
    });
    const element = document.querySelector(`.td_list[data-id="${vehiculoId}"]`);
    if (element) {
      element.classList.add("effect_select");
        setTimeout(() => {
        element.classList.remove("effect_select");
      }, 500);
    }
  };  

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (refreshFiltro) {
      getFilterDataFiltros();
    }
  }, [refreshFiltro]);

  /************************ *+************************/

  /**********   PETICIONES AL BACK   ***************/

  const getVehicle = async () => {
    const response = await getVehiculoList();
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    console.log(params.results);

    setLoading(false);
  };

  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getVehiculoFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const getFilterDataFiltros = async () => {
    setLoading(true);
    const response = await getVehiculoFiltros(
      `?marca=${marcaFiltro}&year=${yearFiltro}&linea=${lineaFiltro}&version=${versionFiltro}`
    );
    console.log(response);

    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setRefreshFiltro();
    setLoading(false);
  };

  const getMarcas = async () => {
    const response = await getMarcaList();
    setMarcaList(response.data);
  };
  const getYear = async () => {
    const response = await getYearFilter(
      `get_year_marca/?marca=${selectedMarca}`
    );
    console.log(response.data);

    setYearList(response.data);
  };

  const getLinea = async () => {
    const response = await getLineaFilter(
      `get_linea_year/?year=${selectedYear}`
    );
    setLineaList(response.data);
    console.log(response.data);
  };

  const getVersion = async () => {
    const response = await getVersionFilter(
      `get_version_linea/?linea=${selectedLinea}`
    );
    setVersionList(response.data);
    console.log(response.data);
  };

  useEffect(() => {
    if (refresh) {
      getVehicle();
    }
  }, [refresh]);

  useEffect(() => {
    setLoading(true);
    getVehicle();
    getMarcas();
  }, []);

  useEffect(() => {
    if (selectedMarca) {
      getYear();
    }
  }, [selectedMarca]);

  useEffect(() => {
    if (selectedYear) {
      getLinea();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedLinea) {
      getVersion();
    }
  }, [selectedLinea]);

  /****************************** *****************************/

  const filteredVehiculos = params.results.filter((vehiculo) =>
    vehiculo.marca.toLowerCase().includes(searchProductos.toLowerCase())
  );

  return (
    <main className="container_productos_true_contact_add main_container">
      <div className="content_nav_vehiculos">
        <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
          <ButtonVolver />
          <div>
            <span className="title_productos">
              Vehículos - Agregar imágenes
            </span>
          </div>
        </div>
        <div className="content_search">
          <input
            type="text"
            className="input_buscar_producto"
            placeholder="Buscar producto"
            value={searchProductos}
            onChange={handleSearchProductos}
          />
          <button className="button_search_vehiculo">
            <TbSearch size={20} color="#fff" strokeWidth={2.5} />
          </button>
        </div>
      </div>

      <div className="filtros_container filtros_container_vehiculos">
        <div>
          <h6 className="title_filtros_productos">Filtrar por:</h6>
        </div>
        <div className="content_filtros">
          {/* Filtro de Marca */}
          <div style={{ position: "relative" }} ref={tipoRef}>
            <div
              className="filtro_productos"
              onClick={() => handleShowFiltro("tipo")}
            >
              <span className="span_title">Marca: </span>
              <span className="span_filtro">
                {marcaFiltro || "Todo"}{" "}
                <TbChevronDown strokeWidth={3} color="#e0324b" />
              </span>
            </div>
            <div
              className={`options_filtros ${
                showFiltro === "tipo" ? "show" : ""
              }`}
            >
              <button
                onClick={() => handleOptionSelect("marca", "")}
                className="button_filtro_producto"
              >
                Todo
              </button>
              {marcaList
                ? Array.isArray(marcaList)
                  ? marcaList.map((marca) => (
                      <button
                        onClick={() => {
                          handleOptionSelect("marca", marca.name);
                          setSelectedMarca(marca.id);
                        }}
                        key={marca.id}
                        className="button_filtro_producto"
                      >
                        {marca.name}
                      </button>
                    ))
                  : []
                : null}
            </div>
          </div>

          {/* Filtro de Años */}
          <div style={{ position: "relative" }} ref={categoriaRef}>
            <div
              className="filtro_productos"
              onClick={() => handleShowFiltro("categoria")}
            >
              <span className="span_title">Año: </span>
              <span className="span_filtro">
                {yearFiltro || "Todo"}{" "}
                <TbChevronDown strokeWidth={3} color="#e0324b" />
              </span>
            </div>
            <div
              className={`options_filtros ${
                showFiltro === "categoria" ? "show" : ""
              }`}
            >
              <button
                onClick={() => handleOptionSelect("year", "")}
                className="button_filtro_producto"
              >
                Todo
              </button>
              {yearList
                ? Array.isArray(yearList)
                  ? yearList.map((year) => (
                      <button
                        key={year.id}
                        onClick={() => {
                          handleOptionSelect("year", year.name);
                          setSelectedYear(year.id);
                        }}
                        className="button_filtro_producto"
                      >
                        {year.name}
                      </button>
                    ))
                  : []
                : null}
            </div>
          </div>

          {/* Filtro de Referencia */}
          <div style={{ position: "relative" }} ref={referenciaRef}>
            <div
              className="filtro_productos"
              onClick={() => handleShowFiltro("referencia")}
            >
              <span className="span_title">Línea: </span>
              <span className="span_filtro">
                {lineaFiltro || "Todo"}{" "}
                <TbChevronDown strokeWidth={3} color="#e0324b" />
              </span>
            </div>
            <div
              className={`options_filtros ${
                showFiltro === "referencia" ? "show" : ""
              }`}
            >
              <button
                onClick={() => handleOptionSelect("year", "")}
                className="button_filtro_producto"
              >
                Todo
              </button>
              {lineaList
                ? Array.isArray(lineaList)
                  ? lineaList.map((linea) => (
                      <button
                        key={linea.id}
                        onClick={() => {
                          handleOptionSelect("linea", linea.name);
                          setSelectedLinea(linea.id);
                        }}
                        className="button_filtro_producto"
                      >
                        {linea.name}
                      </button>
                    ))
                  : []
                : null}
            </div>
          </div>

          {/* Filtro de Rin */}
          <div style={{ position: "relative" }} ref={rinRef}>
            <div
              className="filtro_productos"
              onClick={() => handleShowFiltro("rin")}
            >
              <span className="span_title">Versión: </span>
              <span className="span_filtro">
                {"Todo"} <TbChevronDown strokeWidth={3} color="#e0324b" />
              </span>
            </div>
            <div
              className={`options_filtros ${
                showFiltro === "rin" ? "show" : ""
              }`}
            >
              <button
                onClick={() => handleOptionSelect("year", "")}
                className="button_filtro_producto"
              >
                Todo
              </button>
              {versionList
                ? Array.isArray(versionList)
                  ? versionList.map((version) => (
                      <button
                        key={version.id}
                        onClick={() => {
                          setSelectedVersion(version.id);
                          handleVersionSelect(version.id);
                        }}
                        className="button_filtro_producto"
                      >
                        {version.name}
                      </button>
                    ))
                  : []
                : null}
            </div>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "2rem" }}>
        <table className="tabla_productos" responsive>
          <thead className="thead_lista_productos">
            <tr className="th_lista">
              <th></th>

              <th>Marca</th>

              <th>Línea</th>

              <th>Versión</th>

              <th>Año</th>

              <th
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  paddingTop: "15px",
                  width: "100%",
                }}
              >
                <label
                  className="label_imagen_producto_vehiculo"
                  htmlFor="imagenes_producto"
                  onClick={handleClickAddImages}
                >
                  Agregar Imágenes
                </label>
                <input
                  className="input_imagen_producto"
                  id="imagenes_producto"
                  type="file"
                  onChange={handleImagenChange}
                  disabled={selectedProduct.length === 0}
                />
              </th>
            </tr>
          </thead>
          <tbody className="tbody_lista_productos">
            {loading ? (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  <LoadingCarga />
                </td>
              </tr>
            ) : filteredVehiculos.length > 0 ? (
              filteredVehiculos.map((vehiculo) => {
                const imageUrl = vehiculo.imagen
                  ? `${BASE_URL.replace("/api", "")}${vehiculo.imagen}`
                  : ImagenNoDisponible;
                const isSelected = selectedVersionVehicles.includes(
                  vehiculo.id
                );
                return (
                  <tr
                    data-id={vehiculo.id}
                    className={`td_list ${isSelected ? "effect_select" : ""} `}
                    key={vehiculo.id}
                  >
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        multiple
                        checked={selectedProduct.includes(vehiculo.id)}
                        onChange={() => handleCheck(vehiculo.id)}
                      />
                    </td>
                    <td>{vehiculo.marca}</td>
                    <td>{vehiculo.linea}</td>
                    <td>{vehiculo.version}</td>
                    <td>{vehiculo.year}</td>
                    <td className="content_img_vehiculo">
                      <img
                        src={imageUrl}
                        className="vehiculo_imagen_add_list"
                        alt=""
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  No hay vehículos disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showModal && (
        <section className="modal_llantas">
          <div className="open_modal_llantas">
            <div
              className="modal_content_llantas animation_modal"
              style={{
                width: "400px",
                height: "400px",
                display: "grid",
                alignContent: "center",
                placeContent: "center",
                gap: "3rem",
              }}
            >
              <div className="content_info_imagen_vehiculo">
                <h6 className="title_generar_excel">Imagen seleccionada:</h6>
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="Vista previa"
                    className="preview_img"
                  />
                ) : (
                  <p>No se ha seleccionado ninguna imagen.</p>
                )}
              </div>

              <div className="content_buttons_excel">
                <button
                  className="button_enviar_excel"
                  onClick={handleConfirmImages}
                >
                  Guardar
                </button>
                <button
                  className="button_cancelar_excel"
                  onClick={handleCloseModal}
                >
                  Borrar
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      <div style={{ padding: "2rem 0" }}>
        <Pagination
          count={params.count}
          next={params.next}
          previous={params.previous}
          limit={12}
          baseUrl="/vehiculo/vehiculo/"
          filterFunction={getFilterData}
        />
      </div>
      {loadingEnvio && <LoadingCargaFullScreen />}
    </main>
  );
};

export default AddImagenes;