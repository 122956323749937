import React, { useState, useEffect, useRef } from 'react';
import ContainerTienda from '../../components/TiendaLayout/ContainerTienda';
import { MetaTags } from 'react-meta-tags';
import './styles/Pagar.css';
import { useCart } from '../ProductosLlantas/components/CartContext';
import { TbFileDescription, TbTruckDelivery, TbCreditCard } from "react-icons/tb";
import wompi from "../../assets/images/logo-wompi.svg";
import { TbChevronRight } from 'react-icons/tb';
import Llanta from '../../assets/images/llantas/neumatico-albacete-vector.png'

const Pagar = () => {
  const { cart } = useCart();
  const cantidadProductos = cart.reduce((cantidad, item) => cantidad + item.quantity, 0);
  const totalPagar = cart.reduce((total, item) => total + item.quantity * Number(item.precio), 0);
  const formatPrecio = (precio) => {
    return precio.toLocaleString('es-ES');
  };
  const iva = totalPagar * 0.20;

  const BASE_URL = process.env.REACT_APP_API_URL;

  const getImageUrl = (imagePath) => {
    return imagePath ? `${BASE_URL.replace("/api", "")}${imagePath}` : Llanta;
  };

  const documentoRef = useRef(null);
  
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([false, false, false]);
  const [showSelect, setShowSelect] = useState(false)
  const [formData, setFormData] = useState({
    tipoDocumento: '',
    documento: '',
    nombre: '',
    apellidos: '',
    email: '',
    departamento: '',
    ciudad: '',
    telefono: '',
    direccion: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClickOutside = (e) => {
    if (
      documentoRef.current &&
      !documentoRef.current.contains(e.target) 
    ) {
      setShowSelect(null);
    }
  }
useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log('FormData:', formData);
    const allFieldsFilled = Object.values(formData).every((field) => field.trim() !== '');
    console.log('Is Form Valid:', allFieldsFilled);
    setIsFormValid(allFieldsFilled);
  }, [formData]);

  const handleNextStep = () => {
    if (isFormValid) {
      setCompletedSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        newSteps[currentStep - 1] = true;
        return newSteps;
      });
      setCurrentStep((prevStep) => prevStep + 1);
      console.log(isFormValid);
    }
  };

  const handleStepClick = (step) => {
    if (completedSteps[step - 1] || step === currentStep || currentStep[step +1]) {
      setCurrentStep(step);
    }
  };

  const handleShowSelect = () => {
    setShowSelect((prevState) => !prevState)
  }

  const handleSelectOption = (value) => {
    setFormData({
      ...formData,
      tipoDocumento:value,
    });
    setShowSelect(false)
  }

  return (
    <ContainerTienda>
      <MetaTags>
        <title>Comprar - Colombia Ruedas</title>
      </MetaTags>
      <main className="container_form_pagar">
        <section>
          <div className="container_form_pasos">
            <article className="content_pasos">
              <div
                className={`pasos ${currentStep === 1 ? "active_pasos" : ""} ${
                  currentStep > 1 && "active_pasos_next"
                }`}
                onClick={() => handleStepClick(1)}
              >
                <div className="icon_pasos">
                  <TbFileDescription />
                </div>
                <div>
                  <p className="p_pasos">Datos personales</p>
                </div>
              </div>

              <div
                className={`pasos ${currentStep === 2 ? "active_pasos" : ""} ${
                  currentStep > 2 && "active_pasos_next"
                }`}
                onClick={() => handleStepClick(2)}
              >
                <div className="icon_pasos">
                  <TbTruckDelivery />
                </div>
                <div>
                  <p className="p_pasos">Envío</p>
                </div>
              </div>

              <div
                className={`pasos ${currentStep === 3 ? "active_pasos" : ""} ${
                  currentStep > 3 && "active_pasos_next"
                }`}
                onClick={() => handleStepClick(3)}
              >
                <div className="icon_pasos">
                  <TbCreditCard />
                </div>
                <div>
                  <p className="p_pasos">Pagar</p>
                </div>
              </div>
            </article>

            <form className="form_pagar" action="">
              {/* Datos Personales */}
              {currentStep === 1 && (
                <>
                  <div className="content_label_input" ref={documentoRef}>
                    <label className="label_pagar" htmlFor="tipoDocumento">
                      Tipo Documento
                    </label>
                    <div
                      className="input_pagar"
                      style={{
                        paddingLeft: "rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => handleShowSelect()}
                    >
                      <div>{formData.tipoDocumento || "Seleccionar..."}</div>
                      <TbChevronRight
                        className={`${showSelect ? "rotate" : "icon_chevron"}`}
                      />
                    </div>
                    <div
                      className={`select_pagar ${
                        showSelect ? "show_salect_pagar" : ""
                      }`}
                    >
                      <div onClick={() => handleSelectOption('cedula')} className="button_select_pagar">Cédula</div>
                      <div onClick={() => handleSelectOption('nit')} className="button_select_pagar">NIT</div>
                    </div>
                    
                  </div>

                  <div className="content_label_input">
                    <label className="label_pagar" htmlFor="documento">
                      Documento
                    </label>
                    <input
                      className="input_pagar"
                      type="text"
                      name="documento"
                      value={formData.documento}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="content_label_input">
                    <label className="label_pagar" htmlFor="nombre">
                      Nombre
                    </label>
                    <input
                      className="input_pagar"
                      type="text"
                      name="nombre"
                      value={formData.nombre}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="content_label_input">
                    <label className="label_pagar" htmlFor="apellidos">
                      Apellidos
                    </label>
                    <input
                      className="input_pagar"
                      type="text"
                      name="apellidos"
                      value={formData.apellidos}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="content_label_input">
                    <label className="label_pagar" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="input_pagar"
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="content_label_input">
                    <label className="label_pagar" htmlFor="departamento">
                      Departamento
                    </label>
                    <input
                      className="input_pagar"
                      type="text"
                      name="departamento"
                      value={formData.departamento}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="content_label_input">
                    <label className="label_pagar" htmlFor="ciudad">
                      Ciudad
                    </label>
                    <input
                      className="input_pagar"
                      type="text"
                      name="ciudad"
                      value={formData.ciudad}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="content_label_input">
                    <label className="label_pagar" htmlFor="telefono">
                      Teléfono
                    </label>
                    <input
                      className="input_pagar"
                      type="text"
                      name="telefono"
                      value={formData.telefono}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="content_label_input">
                    <label className="label_pagar" htmlFor="direccion">
                      Tu Dirección
                    </label>
                    <input
                      className="input_pagar"
                      type="text"
                      name="direccion"
                      value={formData.direccion}
                      onChange={handleInputChange}
                    />
                  </div>
                </>
              )}

              {/* Envio */}
              {currentStep === 2 && (
                <>
                  <div className="container_info_envios">
                    <label className="label_pagar">
                      Enviaremos tus llantas a:
                    </label>
                    <div className="content_envio_card">
                      <p className="p_envio_llanta_pagar">
                        ARAUCA, CRAVO NORTE, CALLE 1 Y 2
                      </p>
                      <a className="cambiar_envio_destino" href="#">
                        Cambiar
                      </a>
                    </div>
                  </div>

                  <div className="container_info_envios">
                    <label className="label_pagar">
                      Selecciona el tipo de envío:
                    </label>
                    <div className="content_envio_card envio_card_cursor">
                      <p className="p_envio_llanta_pagar">Envío estándar</p>
                      <p className="p_envio_llanta_pagar_">$ 100.000</p>
                    </div>
                  </div>
                </>
              )}

              {/* Pagar */}
              {currentStep === 3 && (
                <div className="container_info_envios">
                  <label className="label_pagar">¿Cómo deseas pagar?</label>
                  <div className="content_envio_card envio_card_cursor content_img_pagar_form">
                    <img src={wompi} alt="" />
                  </div>
                </div>
              )}
              <div className="content_button_pagar_products">
                <button
                  type="button"
                  onClick={handleNextStep}
                  disabled={!isFormValid}
                  className={`_button_pagar_siguiente ${
                    !isFormValid ? "disabled_button_siguiente_pagar" : ""
                  }`}
                >
                  Siguente paso
                </button>
              </div>
            </form>
          </div>
        </section>

        <section className="container_productos_pagar">
          <div className="container_productos_pagar__">
            <div className="content_title_productos_pagar">
              <h4>Tu compra </h4>
              <span style={{ paddingTop: "2px" }}>
                ({cantidadProductos} productos)
              </span>
            </div>
            <div className="container_productos_a_pagar">
              {cart.map((llanta) => (
                <div className="content_producto_pagar" key={llanta.id}>
                  <div className="content_img_pagar">
                    <img
                      className="img_pagar"
                      src={getImageUrl(llanta.imagenes[0]?.imagen)}
                      alt={llanta.name}
                    />
                  </div>
                  <div className="content_info_product_pagar">
                    <span className="name_llanta_pagar">{llanta.name}</span>
                    <span className="cantidad_llanta_pagar">
                      Cantidad: {llanta.quantity}
                    </span>
                    <span className="precio_pagar">
                      Precio C/U: ${formatPrecio(parseFloat(llanta.precio))}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="container_precios_pagar">
              <div className="content_precio_pagar">
                <span className="title_precio_pagar">Sub-Total:</span>
                <span className="precio_pagar_total">
                  $ {formatPrecio(parseFloat(totalPagar))}
                </span>
              </div>
              <div className="content_precio_pagar">
                <span className="title_precio_pagar">IVA (20%):</span>
                <span className="precio_pagar_total">
                  $ {formatPrecio(parseFloat(iva))}
                </span>
              </div>
              <div className="content_precio_pagar">
                <span className="title_precio_pagar">Total:</span>
                <span className="precio_pagar_total">
                  $ {formatPrecio(parseFloat(totalPagar + iva))}
                </span>
              </div>
              <div className="info_entrega">
                <p className="tiempo_entrega">
                  Tiempo de entrega de 3 a 5 días hábiles.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container_button_detalle">
          <div className="content_toal_precio">
            <span className="title_precio_pagar_">Total:</span>
            <span className="precio_pagar_">
              $ {formatPrecio(totalPagar + iva)}
            </span>
          </div>
          <div>
            <button
              onClick={handleNextStep}
              disabled={!isFormValid}
              className={`button_siguiente_pagar ${
                !isFormValid ? "disabled_button_siguiente" : ""
              }`}
            >
              Siguiente paso
            </button>
          </div>
        </section>
      </main>
    </ContainerTienda>
  );
};

export default Pagar;
