import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Swal from "sweetalert2";
import VehiculoTire from '../ComponentsModalVehiculo/VehiculoTire';
import Carro from '../../../assets/images/tipo-vehiculo/auto.svg';
import marca from '../../../assets/images/audi-Logo_2016.svg';
import medida from '../../../assets/images/ruler-3.svg';
import tamaño from '../../../assets/images/dimensions.svg';
import tipo from '../../../assets/images/aperture.svg';
import portafolio from '../../../assets/images/briefcase.svg';




const LlantasModal = ({ modalLlantas, setModalLlantas, onClose }) => {
  const [modalVehiculo, setModalVehiculo] = useState(false);

  const handleModalVehiculo = () => {
    setModalVehiculo(true);
    
  };

  useEffect(() => {}, []);
  
  

  const handleAlert = () => {
    Swal.fire ({
      icon: 'info',
      title: '¡Estamos trabajando en ello!',
      timer: 2000,
    })   
  }

  return (
    <div>
      <div className="open_modal_llantas_">
        <div
          data-aos="fade-up"
          data-aos-duration="400"
          className={`modal_content_llantas  ${
            modalVehiculo ? "modal_oculto" : ""
          }`}
        >
          <div
            className="closed_modal_llantas"
            onClick={() => setModalLlantas(!modalLlantas)}
          >
            X
          </div>
          <div>
            <div className="content_title_modal_llantas">
              <h1 className="title_modal_llantas">Buscar Llantas por:</h1>
            </div>
            <div className="options_modal_llantas">
              <div
                onClick={handleModalVehiculo}
                className="vehiculo_seleccionar_llantas"
                to="#"
              >
                <div className="container_imagen_llantas">
                  <img className="imagen_vehiculo_llantas" src={Carro} alt="" />
                </div>
                <div className="container_name_vehiculo_llantas">
                  <p className="name_vehiculo_llantas">Vehículo</p>
                </div>
              </div>
              <div
                onClick={handleAlert}
                className="vehiculo_seleccionar_llantas"
                to="#"
              >
                <div className="container_imagen_llantas">
                  <img className="imagen_vehiculo_llantas" src={marca} alt="" />
                </div>
                <div className="container_name_vehiculo_llantas">
                  <p className="name_vehiculo_llantas">Marca</p>
                </div>
              </div>
              <div
                onClick={handleAlert}
                className="vehiculo_seleccionar_llantas"
                to="#"
              >
                <div className="container_imagen_llantas">
                  <img
                    className="imagen_vehiculo_llantas"
                    src={medida}
                    alt=""
                  />
                </div>
                <div className="container_name_vehiculo_llantas">
                  <p className="name_vehiculo_llantas">Medida</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="content_title_modal_llantas">
              <h1 className="title_modal_llantas">Buscar Rines por:</h1>
            </div>
            <div className="options_modal_llantas">
              <div
                onClick={handleAlert}
                className="vehiculo_seleccionar_llantas"
                to="#"
              >
                <div className="container_imagen_llantas">
                  <img
                    className="imagen_vehiculo_llantas"
                    src={tamaño}
                    alt=""
                  />
                </div>
                <div className="container_name_vehiculo_llantas">
                  <p className="name_vehiculo_llantas">Tamaño</p>
                </div>
              </div>
              <div
                onClick={handleAlert}
                className="vehiculo_seleccionar_llantas"
                to="#"
              >
                <div className="container_imagen_llantas">
                  <img className="imagen_vehiculo_llantas" src={tipo} alt="" />
                </div>
                <div className="container_name_vehiculo_llantas">
                  <p className="name_vehiculo_llantas">Tipo</p>
                </div>
              </div>
              <div
                onClick={handleAlert}
                className="vehiculo_seleccionar_llantas"
                to="#"
              >
                <div className="container_imagen_llantas">
                  <img
                    className="imagen_vehiculo_llantas"
                    src={portafolio}
                    alt=""
                  />
                </div>
                <div className="container_name_vehiculo_llantas">
                  <p className="name_vehiculo_llantas">Portafolio</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalVehiculo ? (
        <div className="modal_llantas">
          <VehiculoTire
            modalVehiculo={modalVehiculo}
            setModalVehiculo={setModalVehiculo}
            // setModal={setModal}
            // modal={modal}
          />
        </div>
      ) : null}
    </div>
  );
}

export default LlantasModal