import React, { useEffect } from 'react'
import ContainerTienda from '../../components/TiendaLayout/ContainerTienda'
import "./PoliticasPrivacidad.css"
import Llanta from "../../assets/images/llantas/politica-privacidad.png"
import { MetaTags } from 'react-meta-tags'

const PoliticasPrivacidad = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContainerTienda>
      <MetaTags>
        <title>Colombia Ruedas | Políticas de Privacidad</title>
      </MetaTags>
      <main className="content_politicas_privacidad container_productos_true_contact">
        <div style={{ padding: "1rem 0" }}>
          <h2>Políticas de privacidad</h2>
          <h2>POLÍTICAS DE PROTECCIÓN WEB</h2>
        </div>
        <article className="content_indice">
          <div>
            <h3 className="title_politicas_privacidad">Tabla de contenido</h3>
            <ul>
              <li>OBJETIVO</li>
              <li>BASE LEGAL Y ÁMBITO DE APLICACIÓN</li>
              <li>DEFINICIONES</li>
              <li>AUTORIZACIÓN DE LA POLÍTICA DE TRATAMIENTO</li>
              <li>RESPONSABLE DEL TRATAMIENTO</li>
              <li>TRATAMIENTO Y FINALIDADES DE LAS BASES DE DATOS</li>
              <li>DATOS DE NAVEGACIÓN</li>
              <li>COOKIES O WEB BUGS</li>
              <li>DERECHOS DE LOS TITULARES</li>
              <li>ATENCIÓN A LOS TITULARES DE DATOS</li>
              <li>
                PROCEDIMIENTOS PARA EJERCER LOS DERECHOS DEL TITULAR
                <ul>
                  <li>Derecho de acceso o consulta</li>
                  <li>Derechos de quejas y reclamos</li>
                </ul>
              </li>
              <li>MEDIDAS DE SEGURIDAD</li>
              <li>TRANSFERENCIA DE DATOS A TERCEROS PAÍSES</li>
              <li>VIGENCIA</li>
              <li>APENDICE</li>
              <li>ELABORACION Y APROBACION DEL DOCUMENTO</li>
              <li>HISTORICO DE DOCUMENTOS</li>
            </ul>
          </div>
          <div className="content_img_politi">
            <img className="img_politi" src={Llanta} alt="" />
          </div>
        </article>
        <section>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">Objetivo</h3>
            <p>
              Dar a conocer las acciones que COLOMBIA RUEDA SAS realiza cuando
              los usuarios visitan o navegan el sitio Web, e informar el buen
              uso que se dan a los datos personales en cumplimiento de las
              políticas internas de seguridad y la ley de protección de datos de
              personales.
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">
              Base legal y ámbitos de aplicación
            </h3>
            <p>
              La política de tratamiento de la información se desarrolla en
              cumplimiento de los artículos 15 y 20 de la Constitución Política;
              de los artículos 17 literal k) y 18 literal f) de la Ley
              Estatutaria 1581 de 2012, por la cual se dictan disposiciones
              generales para la Protección de Datos Personales (LEPD), del
              artículo 2.2.2.25.1.1 sección 1 capítulo 25 del Decreto 1074 de
              2015, el cual, reglamenta parcialmente la Ley 1581 de 2012
              (Artículo 13 del Decreto 1377 de 2013). Esta política será
              aplicable a todos los datos personales registrados en bases de
              datos que sean objeto de tratamiento por el Responsable del
              tratamiento.
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">Definiciones</h3>
            <p>
              Establecidas en el artículo 3 de la Ley Estatutaria 1581 de 2012 y
              artículo 2.2.2.25.1.3 Capitulo 25 del Decreto compilatorio 1074 de
              2015 (Artículo 3 del Decreto 1377 de 2013).
            </p>
            <div>
              <p>
                <span className='span_p_p'>Autorización:</span> Consentimiento previo, expreso e
                informado del Titular para llevar a cabo el tratamiento de datos
                personales.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Aviso de privacidad:</span> Comunicación verbal o escrita
                generada por el responsable, dirigida al Titular para el
                tratamiento de sus datos personales, mediante la cual se le
                informa acerca de la existencia de las políticas de tratamiento
                de información que le serán aplicables, la forma de acceder a
                las mismas y las finalidades del tratamiento que se pretende dar
                a los datos personales.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Base de Datos:</span> Conjunto organizado de datos
                personales que sera objeto de tratamiento.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Cookie:</span> Es una pequeña información enviada por un
                sitio web y almacenada en el navegador del usuario, de manera
                que el sitio web puede consultar la actividad previa del
                usuario. Sus principales funciones son: i) Llevar el control
                cuando un usuario introduce su nombre de usuario y contraseña,
                para que no tenga que estar introduciéndolas para cada página.
                Pero no identifica a una persona, sino a una combinación de
                computadora de la clase de computación con navegador y usuario.
                ii) Conseguir información sobre los hábitos de navegación del
                usuario, e intentos de spyware(programas espía), por parte de
                agencias de publicidad y otros. Lo anterior, puede causar
                problemas de privacidad y es una de las razones por la que las
                cookiestienen detractores.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Dato personal:</span> Cualquier información vinculada o
                que pueda asociarse a una o varias personas naturales
                determinadas o determinables.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Dato público:</span> Es el dato que no sea semiprivado,
                privado o sensible. Son considerados datos públicos, entre
                otros, los datos relativos al estado civil de las personas, a su
                profesión u oficio y a su calidad de comerciante o de servidor
                público. Por su naturaleza, los datos públicos pueden estar
                contenidos, entre otros, en registros públicos, documentos
                públicos, gacetas y boletines oficiales y sentencias judiciales
                debidamente ejecutoriadas que no estén sometidas a reserva.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Datos sensibles:</span> Se entiende por datos sensible
                aquellos que afectan la intimidad del Titular o cuyo uso
                indebido puede generar su discriminación, tales como aquellos
                que revelen el origen racial o étnico, la orientación política,
                las convicciones religiosas o filosóficas, la pertenencia a
                sindicatos, organizaciones sociales, de derechos humanos o que
                promueva intereses de cualquier partido político o que
                garanticen los derechos y garantías de partidos políticos de
                oposición, así como los datos relativos a la salud, a la vida
                sexual, y los datos biométricos.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Encargado del tratamiento:</span> Persona natural o
                jurídica, pública o privada, que por sí misma o en asocio con
                otros, realice el tratamiento de datos personales por cuenta del
                responsable del tratamiento.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Responsable del tratamiento:</span> Persona natural o
                jurídica, pública o privada, que por sí misma o en asocio con
                otros, decida sobre la base de datos y/o el tratamiento de los
                datos.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Titular:</span> Persona natural cuyos datos personales
                sean objeto de tratamiento.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Transferencia:</span> La transferencia de datos tiene
                lugar cuando el responsable y/o encargado del tratamiento de
                datos personales, ubicado en Colombia, envía la información o
                los datos personales a un receptor, que a su vez es responsable
                del tratamiento y se encuentra dentro o fuera del país.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Transmisión:</span> Tratamiento de datos personales que
                implica la comunicación de los mismos dentro o fuera del
                territorio de la República de Colombia cuando tenga por objeto
                la realización de un tratamiento por el encargado por cuenta del
                responsable.
              </p>
            </div>
            <div>
              <p>
                <span className='span_p_p'>Tratamiento:</span> Cualquier operación o conjunto de
                operaciones sobre datos personales, tales como la recolección,
                almacenamiento, uso, circulación o supresión.
              </p>
            </div>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">
              AUTORIZACIÓN DE LA POLÍTICA DE TRATAMIENTO
            </h3>
            <p>
              De acuerdo al artículo 9 de la LEPD, para el tratamiento de datos
              personales se requiere la autorización previa e informada del
              Titular. Mediante la aceptación de la presente política, todo
              Titular que facilite información relativa a sus datos personales
              está consintiendo el tratamiento de sus datos por parte de
              COLOMBIA RUEDA SAS en los términos y condiciones recogidos en la
              misma.
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">
              RESPONSABLE DEL TRATAMIENTO
            </h3>
            <p>
              El responsable del tratamiento de las bases de datos objeto de
              esta política es COLOMBIA RUEDA SAS, cuyos datos de contacto son
              los siguientes:
            </p>
            <ul>
              <li>Dirección: CRA 15 # 80 – 36 OF. 202, BOGOTÁ D.C., BOGOTÁ</li>
              <li>Correo electrónico: direccion@colombiarueda.com</li>
              <li>Teléfono: 7968724</li>
            </ul>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">
              TRATAMIENTO Y FINALIDADES DE LAS BASES DE DATOS
            </h3>
            <p>
              COLOMBIA RUEDA SAS, en el desarrollo de su actividad empresarial,
              lleva a cabo el tratamiento de datos personales relativos a
              personas naturales que están contenidos y son tratados en bases de
              datos destinadas a finalidades legítimas, cumpliendo con la
              Constitución y la Ley. En el “Anexo 1. PL-01. Organización Bases
              de datos” se presenta las distintas bases de datos que manejan la
              empresa, la información y características de cada una de ellas.
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">DATOS DE NAVEGACIÓN</h3>
            <p>
              Es posible visitar el sitio Web sin informar ningún tipo de
              identificación personal. Sin embargo, el sistema de navegación y
              el software necesario para el funcionamiento de esta página web
              puede tener la opción de recoger algunos datos personales, cuya
              transmisión se haya implícita en el uso los protocolos de
              comunicación de Internet. Por su propia naturaleza, la información
              recogida podría permitir la identificación de usuarios a través de
              su asociación con datos de terceros, aunque no se obtenga para ese
              fin. En esta categoría de datos se encuentran, la dirección IP o
              el nombre de dominio del equipo utilizado por el usuario para
              acceder a la página web, la dirección URL, la fecha y hora y otros
              parámetros relativos al sistema operativo del usuario. Estos datos
              se utilizan con el propósito de obtener información estadística
              anónima sobre el uso de la página web o controlar su correcto
              funcionamiento técnico, y se cancelan inmediatamente después de
              ser verificados. Cuando se utiliza la opción de contacto, puede
              elegir si desea proporcionarnos información personal, como, por
              ejemplo, su nombre y dirección postal o electrónica, teléfono,
              entre otros, para que podamos comunicarnos y tramitar su solicitud
              o proporcionar información.
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">COOKIES O WEB BUGS</h3>
            <p>
              Este sitio web no utiliza cookies o web bugs para recabar datos
              personales del usuario, sino que su utilización se limita a
              facilitar al usuario el acceso a la página web. El uso de cookies
              de sesión, no memorizadas de forma permanente en el equipo del
              usuario y que desaparecen cuando cierra el navegador, únicamente
              se limitan a recoger información técnica para identificar la
              sesión con la finalidad de facilitar el acceso seguro y eficiente
              de la página web, con el fin de darle mejor servicio en la página.
              Si no desea permitir el uso de cookies puede rechazarlas o
              eliminar las ya existentes configurando su navegador (Internet
              Explorer, Firefox, Safari, Chrome, entre otros), e inhabilitando
              el código Java Script del navegador en la configuración de
              seguridad. La mayoría de navegadores web permiten gestionar sus
              preferencias de cookies, sin embargo, se debe tener en cuenta que
              si elige bloquearlas puede afectar o impedir el funcionamiento de
              la página. Así mismo, uno de los servicios de terceros que se
              pueden llegar a utilizar para seguir la actividad relacionada con
              el servicio, p.ej. es Google Analytics, por lo que, en caso de no
              desear que se obtenga y utilice información, puede instalar un
              sistema de rechazo («opt-out») en su navegador web, como es:
              tools.google.com/dlpage/gaoptout?hl=None.
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">
              DERECHOS DE LOS TITULARES
            </h3>
            <p>
              De acuerdo con el artículo 8 de la LEPD y a los artículos 21 y
              artículo 2.2.2.25.4.3 Capitulo 25 del Decreto compilatorio 1074 de
              2015 (Artículo 22 del Decreto 1377 de 2013) los Titulares de los
              datos pueden ejercer una serie de derechos en relación al
              tratamiento de sus datos personales. Estos derechos podrán
              ejercerse por las siguientes personas.
            </p>
            <ul>
              <li>
                Por el Titular, quién deberá acreditar su identidad en forma
                suficiente por los distintos medios que le ponga a disposición
                el responsable.
              </li>
              <li>
                Por sus causahabientes, quienes deberán acreditar tal calidad.
              </li>
              <li>
                Por el representante y/o apoderado del Titular, previa
                acreditación de la representación o apoderamiento.
              </li>
              <li>Por estipulación a favor de otro y para otro.</li>
            </ul>
            <p>
              Los derechos de los niños, niñas o adolescentes se ejercerán por
              las personas que estén facultadas para representarlos. Los
              derechos del Titular son los siguientes:
            </p>
            <ul>
              <li>
                Derecho de acceso o consulta: Se trata del derecho del Titular a
                ser informado por el responsable del tratamiento, previa
                solicitud, respecto al origen, uso y finalidad que le han dado a
                sus datos personales.
              </li>
              <li>
                Derechos de quejas y reclamos: La Ley distingue cuatro tipos de
                reclamos: – Reclamo de corrección: Es el derecho del Titular a
                que se actualicen, rectifiquen o modifiquen aquellos datos
                parciales, inexactos, incompletos, fraccionados, que induzcan a
                error, o aquellos cuyo tratamiento esté expresamente prohibido o
                no haya sido autorizado. – Reclamo de supresión: Es el derecho
                del Titular a que se supriman los datos que resulten
                inadecuados, excesivos o que no respeten los principios,
                derechos y garantías constitucionales y legales. – Reclamo de
                revocación: Es el derecho del Titular a dejar sin efecto la
                autorización previamente prestada para el tratamiento de sus
                datos personales. – Reclamo de infracción: Es el derecho del
                Titular a solicitar que se subsane el incumplimiento de la
                normativa en materia de Protección de Datos.
              </li>
              <li>
                Derecho a solicitar prueba de la autorización otorgada al
                responsable del tratamiento: Salvo cuando expresamente se
                exceptúe como requisito para el tratamiento de conformidad con
                lo previsto en el artículo 10 de la LEPD.
              </li>
              <li>
                Derecho a presentar ante la Superintendencia de Industria y
                Comercio quejas por infracciones: El Titular o causahabiente
                solo podrá elevar esta queja una vez haya agotado el trámite de
                consulta o reclamo ante el responsable del tratamiento o
                encargado del tratamiento.
              </li>
            </ul>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">
              ATENCIÓN A LOS TITULARES DE DATOS
            </h3>
            <p>
              El Oficial de Protección de Datos de COLOMBIA RUEDA SAS será el
              encargado de la atención de peticiones, consultas y reclamos ante
              la cual el Titular de los datos puede ejercer sus derechos.
              Teléfono: 7968724. Correo electrónico:
              direccion@colombiarueda.com.
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">
              PROCEDIMIENTOS PARA EJERCER LOS DERECHOS DEL TITULAR
            </h3>
            <h5>Derecho de acceso o consulta</h5>
            <p>
              Según el artículo 2.2.2.25.4.2 Capitulo 25 del Decreto
              compilatorio 1074 de 2015 (Artículo 21 del Decreto 1377 de 2013),
              el Titular podrá consultar de forma gratuita sus datos personales
              en dos casos:
            </p>
            <ul>
              <li>Al menos una vez cada mes calendario.</li>
              <li>
                Cada vez que existan modificaciones sustanciales de las
                políticas de tratamiento de la información que motiven nuevas
                consultas.
              </li>
            </ul>
            <p>
              Para consultas cuya periodicidad sea mayor a una por cada mes
              calendario, COLOMBIA RUEDA SAS solamente podrá cobrar al Titular
              los gastos de envío, reproducción y, en su caso, certificación de
              documentos. Los costos de reproducción no podrán ser mayores a los
              costos de recuperación del material correspondiente. Para tal
              efecto, el responsable deberá demostrar a la Superintendencia de
              Industria y Comercio, cuando ésta así lo requiera, el soporte de
              dichos gastos.
            </p>
            <p>
              El Titular de los datos puede ejercitar el derecho de acceso o
              consulta de sus datos mediante un escrito dirigido a COLOMBIA
              RUEDA SAS enviado, mediante correo electrónico a:
              direccion@colombiarueda.com,indicando en el Asunto “Ejercicio del
              derecho de acceso o consulta”, o a través de correo postal
              remitido a la CRA 15 # 80 – 36 OF. 202, BOGOTÁ D.C., BOGOTÁ. La
              solicitud deberá contener los siguientes datos: Nombre y apellidos
              del Titular. Fotocopia de la Cédula de Ciudadanía del Titular y,
              en su caso, de la persona que lo representa, así como del
              documento acreditativo de tal representación. Petición en que se
              concreta la solicitud de acceso o consulta. Dirección para
              notificaciones, fecha y firma del solicitante.
            </p>
            <p>
              Documentos acreditativos de la petición formulada, cuando
              corresponda.
            </p>
            <p>
              El Titular podrá elegir una de las siguientes formas de consulta
              de la base de datos para recibir la información solicitada:
            </p>
            <p>
              Visualización en pantalla. Por escrito, con copia o fotocopia
              remitida por correo certificado o no. Fax. Correo electrónico u
              otro medio electrónico. Otro sistema adecuado a la configuración
              de la base de datos o a la naturaleza del tratamiento, ofrecido
              por COLOMBIA RUEDA SAS.
            </p>
            <p>
              Una vez recibida la solicitud, COLOMBIA RUEDA SAS resolverá la
              petición de consulta en un plazo máximo de diez (10) días hábiles
              contados a partir de la fecha de recibo de la misma. Cuando no
              fuere posible atender la consulta dentro de dicho término, se
              informará al interesado, expresando los motivos de la demora y
              señalando la fecha en que se atenderá su consulta, la cual en
              ningún caso podrá superar los cinco (5) días hábiles siguientes al
              vencimiento del primer término. Estos plazos están fijados en el
              artículo 14 de la LEPD.
            </p>
            <p>
              Una vez agotado el trámite de consulta, el Titular o causahabiente
              podrá elevar queja ante la Superintendencia de Industria y
              Comercio.
            </p>
            <h5>Derechos de quejas y reclamos</h5>
            <p>
              El Titular de los datos puede ejercitar los derechos de reclamo
              sobre sus datos mediante un escrito dirigido a COLOMBIA RUEDA SAS
              Enviado, mediante correo electrónico a direccion@colombiarueda.com
              indicando en el Asunto “Ejercicio del derecho de acceso o
              consulta”, o a través de correo postal remitido a CRA 15 # 80 – 36
              OF. 202, BOGOTÁ D.C.,. La solicitud deberá contener los siguientes
              datos:
            </p>
            <p>
              Nombre y apellidos del Titular. Fotocopia de la Cédula de
              Ciudadanía del Titular y, en su caso, de la persona que lo
              representa, así como del documento acreditativo de tal
              representación. Descripción de los hechos y petición en que se
              concreta la solicitud de corrección, supresión, revocación o
              inflación. Dirección para notificaciones, fecha y firma del
              solicitante. Documentos acreditativos de la petición formulada que
              se quieran hacer valer, cuando corresponda.
            </p>
            <p>
              Si el reclamo resulta incompleto, se requerirá al interesado
              dentro de los cinco (5) días siguientes a la recepción del reclamo
              para que subsane las fallas. Transcurridos dos (2) meses desde la
              fecha del requerimiento, sin que el solicitante presente la
              información requerida, se entenderá que ha desistido del reclamo.
            </p>
            <p>
              Una vez recibido el reclamo completo, se incluirá en la base de
              datos una leyenda que diga “reclamo en trámite” y el motivo del
              mismo, en un término no mayor a dos (2) días hábiles. Dicha
              leyenda deberá mantenerse hasta que el reclamo sea decidido.
            </p>
            <p>
              COLOMBIA RUEDA SAS resolverá la petición de consulta en un plazo
              máximo de quince (15) días hábiles contados a partid de la fecha
              de recibo de la misma. Cuando no fuere posible atender al reclamo
              dentro de dicho término, se informará al interesado los motivos de
              la demora y la fecha en que se atenderá su reclamo, la cual en
              ningún caso podrá superar los ocho (8) días hábiles siguientes al
              vencimiento del primer término. Una vez agotado el trámite de
              reclamo, el Titular o causahabiente podrá elevar queja ante la
              Superintendencia de Industria y Comercio.
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">MEDIDAS DE SEGURIDAD</h3>
            <p>
              COLOMBIA RUEDA SAS, con el fin de cumplir con el principio de
              seguridad consagrado en el artículo 4 literal g) de la LEPD, ha
              implementado medidas técnicas, humanas y administrativas
              necesarias para garantizar la seguridad a los registros evitando
              su adulteración, pérdida, consulta, uso o acceso no autorizado o
              fraudulento.
            </p>
            <p>
              Por otra parte, COLOMBIA RUEDA SAS, mediante la suscripción de los
              correspondientes contratos de transmisión, ha requerido a los
              encargados del tratamiento con los que trabaje la implementación
              de las medidas de seguridad necesarias para garantizar la
              seguridad y confidencialidad de la información en el tratamiento
              de los datos personales.
            </p>
            <p>
              A continuación se exponen las medidas de seguridad implantadas por
              COLOMBIA RUEDA SAS, que están recogidas y desarrolladas en su
              Manual Interno de Seguridad (I, II, III, IV).
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">
              TRANSFERENCIA DE DATOS A TERCEROS PAÍSES
            </h3>
            <p>
              De acuerdo con el Título VIII de la LEPD, se prohíbe la
              transferencia de datos personales a países que no proporcionen
              niveles adecuados de protección de datos. Se entiende que un país
              ofrece un nivel adecuado de protección de datos cuando cumpla con
              los estándares fijados por la Superintendencia de Industria y
              Comercio sobre la materia, los cuales en ningún caso podrán ser
              inferiores a los que la presente ley exige a sus destinatarios.
              Esta prohibición no regirá cuando se trate de:
            </p>
            <ul>
              <li>
                nformación respecto de la cual el Titular haya otorgado su
                autorización expresa e inequívoca para la transferencia.
              </li>
              <li>
                Intercambio de datos de carácter médico, cuando así lo exija el
                tratamiento del Titular por razones de salud o higiene pública.
              </li>
              <li>
                Transferencias bancarias o bursátiles, conforme a la legislación
                que les resulte aplicable.
              </li>
              <li>
                Transferencias acordadas en el marco de tratados internacionales
                en los cuales la República de Colombia sea parte, con fundamento
                en el principio de reciprocidad.
              </li>
              <li>
                Transferencias necesarias para la ejecución de un contrato entre
                el Titular y el responsable del tratamiento, o para la ejecución
                de medidas precontractuales siempre y cuando se cuente con la
                autorización del Titular.
              </li>
              <li>
                Transferencias legalmente exigidas para la salvaguardia del
                interés público, o para el reconocimiento, ejercicio o defensa
                de un derecho en un proceso judicial.
              </li>
            </ul>
            <p>
              En los casos no contemplados como excepción, corresponderá a la
              Superintendencia de Industria y Comercio proferir la declaración
              de conformidad relativa a la transferencia internacional de datos
              personales. El Superintendente está facultado para requerir
              información y adelantar las diligencias tendentes a establecer el
              cumplimiento de los presupuestos que requiere la viabilidad de la
              operación.
            </p>
            <p>
              Las transmisiones internacionales de datos personales que se
              efectúen entre un responsable y un encargado para permitir que el
              encargado realice el tratamiento por cuenta del responsable, no
              requerirán ser informadas al Titular ni contar con su
              consentimiento, siempre que exista un contrato de transmisión de
              datos personales.
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">VIGENCIA</h3>
            <p>
              Las bases de datos responsabilidad de COLOMBIA RUEDA SAS, serán
              objeto de tratamiento durante el tiempo que sea razonable y
              necesario para la finalidad para la cual son recabados los datos.
              Una vez cumplida la finalidad o finalidades del tratamiento, y sin
              perjuicio de normas legales que dispongan lo contrario. COLOMBIA
              RUEDA SAS, procederá a la supresión de los datos personales en su
              posesión salvo que exista una obligación legal o contractual que
              requiera su conservación. Por todo ello, dicha base de datos ha
              sido creada sin un periodo de vigencia definido.
            </p>
            <p>
              La presente política de tratamiento permanece vigente desde
              2017-12-15
            </p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">APENDICE</h3>
            <p>No aplica</p>
          </div>
          <div className="content_info_priv">
            <h3 className="title_politicas_privacidad">
              ELABORACION Y APROBACION DEL DOCUMENTO
            </h3>
            <p>Elaboro</p>
            <p>PROTECDATA COLOMBIA SAS</p>
            <p>Aprobo</p>
            <p>Diego Fernando Arenas Osorio Representante legal suplente.</p>
            <p>HISTORICO DE DOCUMENTOS</p>
          </div>
        </section>
      </main>
    </ContainerTienda>
  );
}

export default PoliticasPrivacidad