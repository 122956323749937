import React, { useEffect, useRef, useState } from "react";
import { TbChevronDown } from "react-icons/tb";
import { TbSearch } from "react-icons/tb";
import { getCategoriaFullList, getMedidasFullList, getRinFullList } from "../../../helpers/backend";

const HeaderProductos = ({
  searchProductos,
  onSearchChange,
  onFiltroChange,
  categoria,
  medida,
  rin,
  marca,
}) => {
  const [showFiltro, setShowFiltro] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const [rines, setRines] = useState([]);

  const tipoRef = useRef(null);
  const categoriaRef = useRef(null);
  const rinRef = useRef(null);
  const referenciaRef = useRef(null);

  // Cargar categorías, medidas y rines
  const getCategorias = async () => {
    const response = await getCategoriaFullList();
    setCategorias(response.data);
  };

  const getMedidas = async () => {
    const response = await getMedidasFullList();
    setMedidas(response.data);
  };

  const getRines = async () => {
    const response = await getRinFullList();
    setRines(response.data);
  };

  useEffect(() => {
    getCategorias();
    getMedidas();
    getRines();
  }, []);

  const handleShowFiltro = (filtro) => {
    setShowFiltro(showFiltro === filtro ? null : filtro);
  };

  const handleOptionSelect = (filtro, value) => {
    onFiltroChange(filtro, value);
    setShowFiltro(null);
  };

  const handleClickOutside = (e) => {
    if (
      tipoRef.current &&
      !tipoRef.current.contains(e.target) &&
      categoriaRef.current &&
      !categoriaRef.current.contains(e.target) &&
      rinRef.current &&
      !rinRef.current.contains(e.target) &&
      referenciaRef.current &&
      !referenciaRef.current.contains(e.target)
    ) {
      setShowFiltro(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="content_header_productos">
      <div className="filtros_container">
        <div>
          <h6 className="title_filtros_productos">Filtrar por:</h6>
        </div>
        <div className="content_filtros">
          {/* Filtro de Marca */}
          <div style={{ position: "relative" }} ref={tipoRef}>
            <div className="filtro_productos" onClick={() => handleShowFiltro("tipo")}>
              <span className="span_title">Marca: </span>
              <span className="span_filtro">
                {marca || "Todo"} <TbChevronDown strokeWidth={3} color="#e0324b" />
              </span>
            </div>
            <div className={`options_filtros ${showFiltro === "tipo" ? "show_" : ""}`}>
              <button
                onClick={() => handleOptionSelect("marca", "Todo")}
                className="button_filtro_producto"
              >
                Todo
              </button>
              <button
                onClick={() => handleOptionSelect("marca", "SunFull")}
                className="button_filtro_producto"
              >
                SunFull
              </button>
            </div>
          </div>

          {/* Filtro de Categoría */}
          <div style={{ position: "relative" }} ref={categoriaRef}>
            <div className="filtro_productos" onClick={() => handleShowFiltro("categoria")}>
              <span className="span_title">Categoría: </span>
              <span className="span_filtro">{categoria || "Todo"} <TbChevronDown strokeWidth={3} color="#e0324b" /></span>
            </div>
            <div className={`options_filtros ${showFiltro === "categoria" ? "show_" : ""}`}>
              <button onClick={() => handleOptionSelect("categoria", "")} className="button_filtro_producto">
                Todo
              </button>
              {categorias.map((categ) => (
                <button
                  onClick={() => handleOptionSelect("categoria", categ.id)}
                  key={categ.id}
                  className="button_filtro_producto"
                >
                  {categ.name}
                </button>
              ))}
            </div>
          </div>

          {/* Filtro de Referencia */}
          <div style={{ position: "relative" }} ref={referenciaRef}>
            <div className="filtro_productos" onClick={() => handleShowFiltro("referencia")}>
              <span className="span_title">Referencia: </span>
              <span className="span_filtro">{medida || "Todo"} <TbChevronDown strokeWidth={3} color="#e0324b" /></span>
            </div>
            <div className={`options_filtros ${showFiltro === "referencia" ? "show_" : ""}`}>
              <button onClick={() => handleOptionSelect("medida", "")} className="button_filtro_producto">
                Todo
              </button>
              {medidas.map((med) => (
                <button
                  onClick={() => handleOptionSelect("medida", med.id)}
                  key={med.id}
                  className="button_filtro_producto"
                >
                  {med.medidas}
                </button>
              ))}
            </div>
          </div>

          {/* Filtro de Rin */}
          <div style={{ position: "relative" }} ref={rinRef}>
            <div className="filtro_productos" onClick={() => handleShowFiltro("rin")}>
              <span className="span_title">Rin: </span>
              <span className="span_filtro">{rin || "Todo"} <TbChevronDown strokeWidth={3} color="#e0324b" /></span>
            </div>
            <div className={`options_filtros ${showFiltro === "rin" ? "show_" : ""}`}>
              <button onClick={() => handleOptionSelect("rin", "")} className="button_filtro_producto">
                Todo
              </button>
              {rines.map((r) => (
                <button
                  onClick={() => handleOptionSelect("rin", r.id)}
                  key={r.id}
                  className="button_filtro_producto"
                >
                  {r.rin}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="content_search">
        <input
          type="text"
          className="input_buscar_producto"
          placeholder="Buscar por marca"
          value={searchProductos}
          onChange={onSearchChange}
        />
        <button className="button_search">
          <TbSearch size={20} color="#fff" strokeWidth={2.5} />
        </button>
      </div>
    </section>
  );
};

export default HeaderProductos;
