/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from 'react'
import Llantas from "../../../assets/images/llantas-home.webp";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { TbSearch } from "react-icons/tb";
import { TbShoppingBag } from "react-icons/tb";
import AOS from 'aos';
import "aos/dist/aos.css";
import TipoVehiculoModal from './TipoVehiculoModal';



export const HomeInicial = () => {
  const [modal, setModal] = useState(false);
  

  const handleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section id="home" className="home_inicial">
      <div className="section-text hm">
        <h1 className="tittle-home">
          Busca tus llantas nuevas<span className="text_home_span">.</span>
        </h1>
        <p className="parrafo_home">
          Tenemos <span className="text_home_span">+500,000</span> llantas
          disponibles<span className="text_home_span">.</span>
        </p>
        <div className='content_button_hinicial'>
          <Link onClick={handleModal} className="button_home" to="#">
            Buscar llantas <TbSearch size={22} />
          </Link>

          <Link className="button_home" to="/products_market">
            Productos <TbShoppingBag size={22} />
          </Link>
        </div>
      </div>
      <div className="mh">
        <img className="llantas_home" src={Llantas} alt="" />
      </div>
      {modal ? (
        <div className="modal_llantas ">
          <TipoVehiculoModal modal={modal} setModal={setModal} />
        </div>
      ) : null}
    </section>
  );
}
