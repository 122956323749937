import React, { useEffect, useState } from "react";
import { get } from "../../../store/actionsSinLogin/actions";
import { getLineaRead, getMarcaRead, getVersionRead, getYearRead } from "../../../helpers/backend";

const InputsFiltros = ({
  marca,
  marcaUpdate,
  year,
  yearUpdate,
  line,
  lineUpdate,
  version,
  versionUpdate,
}) => {
  const [marcaName, setMarcaName] = useState(null);
  const [yearName, setYearName] = useState(null);
  const [lineName, setLineName] = useState(null);
  const [versionName, setVersionName] = useState(null);

  /// Funciones para la información obtenida de results buttons

  const getMark = async () => {
    const response = await getMarcaRead(marca)
    setMarcaName(response.data)
    
  };

  const getYear = async () => {
    const response = await getYearRead(year)
    setYearName(response.data)
  };

  const getLine = async () => {
    const response = await getLineaRead(line)
    setLineName(response.data)
  };

  const getVersion = async () => {
    const response = await getVersionRead(version)
    setVersionName(response.data)
  };

  /// Efectos de los inputs

  useEffect(() => {
    setMarcaName(null);
    setYearName(null);
    setLineName(null);
    setVersionName(null);
    if (marca) {
      getMark();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marca]);

  useEffect(() => {
    setYearName(null);
    setLineName(null);
    setVersionName(null);
    if (year) {
      getYear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  useEffect(() => {
    setLineName(null);
    setVersionName(null);
    if (line) {
      getLine();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line]);

  useEffect(() => {
    setVersionName(null);
    if (version) {
      getVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  return (
    <section>
      <div className="inputs_container">
        <div className="input_content">
          <label className="label_input" htmlFor="">
            Marca:
          </label>
          <input
            className={
              marca && marcaName ? "input_seleccionado" : "input_vehiculo"
            }
            placeholder="Marca"
            onChange={() => {}}
            onClick={() => {
              marcaUpdate(null);
            }}
            value={marca && marcaName ? marcaName.name : ""}
            type="text"
          />
        </div>

        <div className="input_content">
          <label className="label_input" htmlFor="">
            Año:
          </label>
          <input
            className={
              year && yearName ? "input_seleccionado" : "input_vehiculo"
            }
            onChange={() => {}}
            onClick={() => {
              yearUpdate(null);
            }}
            value={year && yearName ? yearName.name : ""}
            placeholder="Año"
            type="text"
          />
        </div>

        <div className="input_content">
          <label className="label_input" htmlFor="">
            Línea:
          </label>
          <input
            className={
              line && lineName ? "input_seleccionado" : "input_vehiculo"
            }
            type="text"
            onChange={() => {}}
            placeholder="Línea "
            onClick={() => {
              lineUpdate(null);
            }}
            value={line && lineName ? lineName.name : ""}
          />
        </div>

        <div className="input_content">
          <label className="label_input" htmlFor="">
            Versión:
          </label>
          <input
            className={
              version && versionName ? "input_seleccionado" : "input_vehiculo"
            }
            type="text"
            onChange={() => {}}
            onClick={() => {
              versionUpdate(null);
            }}
            value={version && versionName ? versionName.name : ""}
            placeholder="Versión "
          />
        </div>
      </div>
    </section>
  );
};

export default InputsFiltros;
