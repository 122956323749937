import React, { useEffect, useRef, useState } from 'react'
import ButtonVolver from '../../../components/ButtonVolver';

const NavbarEstadisticas = ({ onButtonClick }) => {
  const [buttonActive, setButtonActive] = useState("resaltados");

  const resaltadosRef = useRef(null);
  const clicksRef = useRef(null);
  const correosRef = useRef(null);
  const borderRef = useRef(null)

  useEffect(() => {
    if (buttonActive) {
      const activeRef = {
        resaltados: resaltadosRef,
        correosAbiertos: correosRef,
        clicksCorreos: clicksRef,
      }[buttonActive];

      if (activeRef.current && borderRef.current) {
        borderRef.current.style.left = `${activeRef.current.offsetLeft}px`;
        borderRef.current.style.width = `${activeRef.current.offsetWidth}px`;
      }
    }
  }, [buttonActive]);

  const handleActiveButton = (option) => {
    
    if (buttonActive !== option) {
      setButtonActive(option);
      onButtonClick(option);
    }
  };


  return (
    <nav className="container_nav_estadisticas">
      <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
        <ButtonVolver />
        <div className="buttons_navigation">
          <button
            ref={resaltadosRef}
            onClick={() => handleActiveButton("resaltados")}
            className={`button_navigation_estadistica ${
              buttonActive === "general"
                ? "button_navigation_estadistica_active"
                : ""
            }`}
          >
            Resaltados
          </button>
          <button
            ref={correosRef}
            onClick={() => handleActiveButton("correosAbiertos")}
            className={`button_navigation_estadistica ${
              buttonActive === "organizacion"
                ? "button_navigation_estadistica_active"
                : ""
            }`}
          >
            Correos abiertos
          </button>
          <button
            ref={clicksRef}
            onClick={() => handleActiveButton("clicksCorreos")}
            className={`button_navigation_estadistica ${
              buttonActive === "detalles"
                ? "button_navigation_estadistica_active"
                : ""
            }`}
          >
            Clicks de correos
          </button>
          <div
            ref={borderRef}
            className="border_button_navigation_estadistica"
          ></div>
        </div>
      </div>
    </nav>
  );
}

export default NavbarEstadisticas