import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from './CartContext'; 
import { TbShoppingCart } from "react-icons/tb";
import Llanta from '../../../assets/images/llantas/neumatico-albacete-vector.png'
import Sunfull from '../../../assets/images/marcas/Sunfull - Sin fondo.png'
import FullRun from '../../../assets/images/marcas/fullrun.png'
import BlackHawk from '../../../assets/images/marcas/blackhawk.png'
import Wanli from '../../../assets/images/marcas/Wanli - Sin fondo.png'
import Rovelo from '../../../assets/images/marcas/rovelo.png'
import Troque from '../../../assets/images/marcas/Troque - Sin fondo.png'
import Sunny from '../../../assets/images/marcas/Sunny - Sin fondo.png'
import Hevos from '../../../assets/images/marcas/Hevos - Sin fondo.png'
import Greforce from '../../../assets/images/marcas/Greforce - Sin fondo.png'
import GoldenUnicorn from '../../../assets/images/marcas/Golden unicorn - Sin fondo.png'
import CashLand from '../../../assets/images/marcas/Cachland - Sin fondo.png'
import WindForce from '../../../assets/images/marcas/windforce.png'


const CardLlanta = ({ llanta, listType }) => {
  const { addToCart } = useCart(); 
  const [contadorCantidad, setContadorCantidad] = useState(0);

  const handleAumentar = () => {
    if (contadorCantidad < llanta.stock) {
      setContadorCantidad(contadorCantidad + 1);
    }
  };

  const handleRestar = () => {
    if (contadorCantidad > 0) {
      setContadorCantidad(contadorCantidad - 1);
    }
  };

  const handleAgregarAlCarrito = () => {
    if (contadorCantidad > 0) {
      addToCart({ ...llanta, stock: contadorCantidad }); // Agregar al carrito con la cantidad
      setContadorCantidad(0); // Resetea el contador después de agregar
    }
  };

  const imageUrl =
    llanta.imagenes && llanta.imagenes[0]
      ? `${process.env.REACT_APP_API_URL.replace("/api", "")}${llanta.imagenes[0].imagen}`
      : Llanta;

  const formatPrecio = (precio) => {
    return precio.toLocaleString('es-ES')
  }

  const detallesMostrar = [
    "utqg",
    "lonas",
    "presion_maxima",
    "indice_carga",
    "indice_velocidad",
    "ancho_seccion",
    "diametro_total",
    "m_s",
    "diseño",
    "construccion",

  ];

  const marcasImagenes = {
    "SUNFULL": Sunfull,
    "WANLI": Wanli,
    "BLACKHAWK": BlackHawk,
    "SUNNY": Sunny,
    "FULLRUNN": FullRun,
    "GREFORCE": Greforce,
    "ROVELO": Rovelo,
    "TROQUE": Troque,
    "HEVOS": Hevos,
    "GOLDEN UNICORN": GoldenUnicorn,
    "CASHLAND": CashLand,
    "WINDFORCE": WindForce
  };

  const marcaImagen = marcasImagenes[llanta.marca.toUpperCase()] || null;

  return (
    <div
      key={listType ? "layout" : "card"}
      className={` ${listType ? "cart_llanta_layout" : "cart_llanta"}`}
      data-aos={!listType ? "fade-left" : "fade-up"}
      data-aos-duration="300"
    >
      <div className={` ${listType ? "info_llantas_layout" : "info_llantas"}`}>
        <div
          className={` ${
            listType ? "content_imagenes_layout" : "content_imagenes"
          }`}
        >
          <div
            className={`content_image_llanta ${
              listType ? "content_image_llanta_layout" : ""
            }`}
          >
            <Link
              to={{
                pathname: `/llanta-${llanta.name
                  .replace(/\//g, "-")
                  .replace(/\s+/g, "-")}-${llanta.id}`,
                state: { llanta, imageUrl, marcaImagen },
              }}
            >
              {imageUrl ? (
                <img
                  className="image_llanta"
                  src={imageUrl}
                  alt={`${llanta.name} neumático`}
                />
              ) : (
                <img
                  className="image_llanta"
                  src={Llanta}
                  alt={`${llanta.name} neumático`}
                />
              )}
            </Link>
          </div>
          <div
            className={`content_image_marca ${
              listType ? "image_marca_layout" : ""
            }`}
          >
            {marcaImagen && (
              <img
                className={`image_marca ${
                  listType ? "image_marca_layout" : ""
                }`}
                src={marcaImagen}
                alt={`${llanta.marca} logo`}
              />
            )}
          </div>
        </div>

        <div>
          <div
            className={`${
              listType ? "content_name_card_layout" : "content_name_card"
            }`}
          >
            <Link
              to={{
                pathname: `/llanta-${llanta.name
                  .replace(/\//g, "-")
                  .replace(/\s+/g, "-")}-${llanta.id}`,
                state: { llanta, imageUrl, marcaImagen },
              }}
            >
              <p
                className={`${
                  listType ? "name_llanta_card_layout" : "name_llanta_card"
                }`}
              >
                {llanta.name.toUpperCase()}
              </p>
            </Link>
            <p
              className={`${
                listType ? "medida_llanta_layout" : "medida_llanta"
              }`}
            >
              {llanta.referencia}
            </p>
          </div>

          {!listType ? (
            <div className="content_caracteristicas">
              {llanta.detalles_producto &&
              llanta.detalles_producto.length > 0 ? (
                llanta.detalles_producto.map((detalle, index) => (
                  <div key={index}>
                    {detallesMostrar.map(
                      (clave) =>
                        detalle[clave] && (
                          <p key={clave} className="caracteristicas_llanta">
                            <span className="span_llanta">
                              {clave.replace(/_/g, " ").toUpperCase()}
                            </span>
                            :{" "}
                            {detalle[clave] !== null
                              ? detalle[clave]
                              : "No disponible"}
                          </p>
                        )
                    )}
                  </div>
                ))
              ) : (
                <p>No hay detalles disponibles</p>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <div
        className={`${listType ? "content_precios_layout" : "content_precios"}`}
      >
        <div className={`${listType ? "precios_layout" : "precios"}`}>
          <p className={`${listType ? "precio_viejo_layout" : "precio_viejo"}`}>
            $344.900 c/u
          </p>
          <p className={`${listType ? "precio_nuevo_layout" : "precio_nuevo"}`}>
            ${formatPrecio(parseFloat(llanta.precio))} c/u
          </p>
        </div>

        <div
          className={`${
            listType
              ? "content_buttons_market_layout"
              : "content_buttons_market"
          }`}
        >
          <div className="content_buttons_cantidad">
            <button
              onClick={handleRestar}
              className={`left ${
                listType ? "buttons_cantidad_layout" : "buttons_cantidad"
              }`}
            >
              -
            </button>
            <input
              className={`${
                listType ? "input_cantidad_layout" : "input_cantidad"
              }`}
              type="text"
              value={contadorCantidad}
              readOnly
            />
            <button
              onClick={handleAumentar}
              className={`right ${
                listType ? "buttons_cantidad_layout" : "buttons_cantidad"
              }`}
            >
              +
            </button>
          </div>

          <button
            className={`${
              listType ? "button_agregar_layout" : "button_agregar"
            }`}
            onClick={handleAgregarAlCarrito}
          >
            {!listType ? (
              "Agregar al carrito"
            ) : (
              <>
                Agregar <TbShoppingCart size={20} strokeWidth={2.5} />
              </>
            )}
          </button>
        </div>
        <div>
          <p className={`${listType ? "envios_layout" : "envios"}`}>
            * ENVÍOS GRATIS
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardLlanta;
