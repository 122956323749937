import React, { useEffect, useState } from 'react';
import opcion3 from "../../../assets/images/llantas/llanta_rovelo_rhp780p_5_7.jpg";
import marca3 from "../../../assets/images/marcas/rovelo.png";
import { useCart } from '../../ProductosLlantas/components/CartContext';
import miniatura from "../../../assets/images/llantas/llanta_rovelo_rhp780p_5_7-miniatura.jpg"
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import ContainerTienda from '../../../components/TiendaLayout/ContainerTienda';
import LoadingCargaFullScreen from '../../Loading/LoadingCargaFullScreen';
import ButtonVolver from '../../../components/ButtonVolver';


const llantas = [
  {
    id: 1,
    name: "Blackhawk HH1 Street-h",
    medida: "185/60R15",
    caracteristicas: [
      { clave: "utqg", valor: "420-A-A" },
      { clave: "Lonas", valor: "SL" },
      { clave: "Profundidad de banda", valor: "8 mm" },
      { clave: "Pais", valor: "China" },
      { clave: "Ancho de banda", valor: "" },
      { clave: "Fabricado en", valor: "China" },
      { clave: "Indice de carga", valor: "82(474 Kg)" },
      { clave: "Indice de velocidad", valor: "H(210 km)" },
      { clave: "Presion maxima", valor: "49 PSI" },
    ],
    precio: 239.552,
    cantidad: 28,
    image: opcion3,
    imagen_marca: marca3,
    image_mini: miniatura
  }
];

const Llanta = () => {
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const llantaSeleccionada = state ? state.llanta : null;
  const imagenUrl = state ? state.imageUrl : null;
  const marcaImagen = state ? state.marcaImagen : null;
  const { addToCart } = useCart();
  const [contadorCantidad, setContadorCantidad] = useState(0);

  const handleAumentar = () => {
    if (contadorCantidad < llantas[0].cantidad) {
      setContadorCantidad(contadorCantidad + 1);
    }
  };

  const handleRestar = () => {
    if (contadorCantidad > 0) {
      setContadorCantidad(contadorCantidad - 1);
    }
  };

  const handleAgregarAlCarrito = () => {
    if (contadorCantidad > 0) {
      const llantaParaAgregar = { ...llantas[0], stock: contadorCantidad };
      addToCart(llantaParaAgregar);
      setContadorCantidad(0); // Resetea el contador
    }
  };
  console.log(llantaSeleccionada);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])
  

  const detallesMostrar = [
    "utqg",
    "lonas",
    "diseño",
    "presion_maxima",
    "indice_carga",
    "indice_velocidad",
    "construccion",
    "ancho_seccion",
    "capacidad_carga_maxima",
    "diametro_total",
    "presion_maxima",
    "sin_neumatico"
  ];

  return (
    <div>
      <ContainerTienda>
        <section className="page_llanta_market">
          {loading ? (
            <LoadingCargaFullScreen />
          ) : (
            <div className="container_llanta">
              <div style={{paddingBottom:".5rem"}}>
              <ButtonVolver/>
              </div>
              <div className="content_llanta">
                <div className="content_imagenes_detalles_llanta">
                  <div className="content_imagen_miniatura">
                    <img
                      className="imagen_miniatura"
                      src={imagenUrl}
                      alt={llantaSeleccionada.name}
                    />
                  </div>
                  <div className="container_img_modelo">
                    <div className="content_img_medida">
                      <img className="img_medida" src={imagenUrl} alt="" />
                    </div>
                    <div className="content_img_marca">
                      <img className="img_marca" src={marcaImagen} alt="" />
                    </div>
                  </div>
                </div>

                <article className="info_llanta">
                  <div className="content_name">
                    <h2 className="name_llanta">
                      {llantaSeleccionada.name.toUpperCase()}
                    </h2>
                    <h4 className="name_medida">
                      Medida {llantaSeleccionada.referencia}
                    </h4>
                  </div>

                  {llantaSeleccionada.detalles_producto &&
                  llantaSeleccionada.detalles_producto.length > 0 ? (
                    llantaSeleccionada.detalles_producto.map(
                      (detalle, index) => (
                        <div className="" key={index}>
                          {detallesMostrar.map(
                            (clave) =>
                              detalle[clave] && (
                                <p
                                  key={clave}
                                  className="info_llantas_caracteristicas"
                                >
                                  <span className="span_llanta">
                                    {clave.replace(/_/g, " ").toUpperCase()}
                                  </span>
                                  :{" "}
                                  {detalle[clave] !== null
                                    ? detalle[clave]
                                    : "No disponible"}
                                </p>
                              )
                          )}
                        </div>
                      )
                    )
                  ) : (
                    <p>No hay detalles disponibles</p>
                  )}
                  {/* {llantaSeleccionada.caracteristicas.map((obj) => (
                  <p key={obj.valor} className="info_llantas_caracteristicas">
                    <span className="span_llanta">{obj.clave}</span>:{" "}
                    {obj.valor} <TbInfoCircleFilled />
                  </p>
                ))} */}
                </article>

                <article className="content_precios_detalles">
                  <div className="precios">
                    <p className="precio_nuevo_market">
                      ${llantaSeleccionada.precio} c/u
                    </p>
                  </div>

                  <div className="content_buttons_market">
                    <div className="container_buttons_cantidad">
                      <span className="cantidad">
                        {llantaSeleccionada.cantidad - 5} DISPONIBLES
                      </span>
                      <div className="content_buttons_cantidad">
                        <button
                          onClick={handleRestar}
                          className="buttons_cantidad left"
                        >
                          -
                        </button>
                        <input
                          className="input_cantidad"
                          type="text"
                          value={contadorCantidad}
                          readOnly
                        />
                        <button
                          onClick={handleAumentar}
                          className="buttons_cantidad right"
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <div className="content_buttom_agregar">
                      <button
                        className="button_agregar"
                        onClick={handleAgregarAlCarrito}
                      >
                        Agregar al carrito
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          )}
        </section>
      </ContainerTienda>
    </div>
  );
};

export default Llanta;
