import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import MetisMenu from "metismenujs";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import { getDataUser } from "../../helpers/backend";
// import logo3 from '../../assets/images/logo-symbol-tc.png'
import logoTrueSmall from '../../assets/images/logo-symbol-tc.png'
import './Menu.css'
import { TbUser, TbUsers, TbFlag,TbFileDescription, TbBuildingStore, TbChartInfographic, TbLayoutDashboard } from "react-icons/tb";


const SidebarContent = (props) => {
  const ref = useRef();
  const user = JSON.parse(localStorage.getItem("user"))
  const groups =String(user.groups);
  const [contactId, setContactId] =  useState(null)

  const getData = async () => {
    const response = await getDataUser();
    const data = response.data[0];
    setContactId(data.id)
  };

  useEffect(() => {
    const initMenu = () => {
      new MetisMenu("#side-menu");
    };

    initMenu();
  }, []);

  useEffect(() => {
    ref.current.recalculate();
  }, []);
  useEffect(() => {
    if(!groups.includes('SuperAdmin')){
      getData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
console.log(contactId);
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul
            style={{ display: "grid", gap: ".5rem" }}
            className="metismenu list-unstyled"
            id="side-menu"
          >
            <li style={{ color: "#c5c8db" }} className="menu-title">
              {props.t("Menu")}{" "}
            </li>

            <li>
              <Link
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: ".7rem",
                }}
                className="opcions_menu_sidebar"
                to="/dashboard"
              >
                <TbLayoutDashboard
                  className="icon_menu"
                  size={22}
                  strokeWidth={2.3}
                />
                <span className="span_menu" key="t-dashboard">
                  Dashboard
                </span>
              </Link>
            </li>
            {groups.includes("SuperAdmin") || groups.includes("Supervisor") ? (
              <>
                {" "}
                <li>
                  <Link
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: ".7rem",
                    }}
                    className="opcions_menu_sidebar"
                    to="#"
                  >
                    <TbUsers className="icon_menu" size={22} strokeWidth={3} />
                    <span className="span_menu" key="t-users">
                      Usuarios
                    </span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/users">
                        <span className="span_submenu">Usuarios</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/rol/add_rol">
                        <span className="span_submenu">Roles</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}
            {groups.includes("SuperAdmin") ||
            groups.includes("Supervisor") ||
            groups.includes("Operador") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".7rem",
                  }}
                  className="opcions_menu_sidebar"
                  to="/campaign"
                >
                  <TbFlag className="icon_menu" size={22} strokeWidth={3} />
                  <span className="span_menu" key="t-Parametrizaciones">
                    Campañas
                  </span>
                </Link>
              </li>
            ) : null}
            <li>
              <Link
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: ".7rem",
                }}
                className="opcions_menu_sidebar"
                to="/contacts"
              >
                <TbUser className="icon_menu" size={22} strokeWidth={3} />
                <span className="span_menu" key="t-contacts">
                  Contactos
                </span>
              </Link>
            </li>
            <li>
              <Link
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: ".3rem",
                }}
                className="opcions_menu_sidebar"
                to="/chat"
              >
                <img src={logoTrueSmall} alt="" height="22" className="me-1" />
                <span className="span_menu" key="t-canales">
                  TrueContact
                </span>
              </Link>
            </li>
            {/*
           <li>
             <Link to="/casos">
               <i className="fas fa-ticket-alt"></i>
               <span key="t-casos">Casos</span>
             </Link>
           </li>
           <li>
              <Link to={`/survey`}>
                <i className="fas fa-question-circle fa-lg "></i>
                <span key="t-survey"> Encuestas </span>
             </Link>
            </li>
           {!groups.includes('SuperAdmin') && (
           <li>
              <Link to={`/contact/calendar/${contactId}`}>
                <i className="  fas fa-calendar-alt fa-lg ms-2"></i>
                <span key="t-calendar"> Mi Agenda </span>
             </Link>
            </li>
          )}*/}
            {groups.includes("SuperAdmin") || groups.includes("Supervisor") ? (
              <li>
                <Link
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: ".7rem",
                  }}
                  className="opcions_menu_sidebar"
                  to="#"
                >
                  <TbFileDescription
                    className="icon_menu"
                    size={22}
                    strokeWidth={2.3}
                  />
                  <span className="span_menu" key="t-file">
                    Reportes
                  </span>
                </Link>
                <ul className="sub-menu" style={{ backgroundColor: "#19243f" }}>
                  <li>
                    <Link to="/managementList">
                      <span className="span_submenu">Lista de gestiones</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/report_supervision">
                      <span className="span_submenu">
                        Reporte de supervición
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/report_for_hour">
                      <span className="span_submenu">Reporte por hora</span>
                    </Link>
                  </li>
                  {/*  <li><Link to="/report_for_time">Promedio de Tiempo</Link></li>*/}
                  <li>
                    <Link to="/report_for_bot">
                      <span className="span_submenu">Ingresados al bot</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/issabelReport">
                      {" "}
                      <span className="span_submenu">Ingresados Issabel</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            <li>
              <Link
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: ".7rem",
                }}
                className="opcions_menu_sidebar"
                to="#"
              >
                <TbBuildingStore
                  className="icon_menu"
                  size={22}
                  strokeWidth={2.1}
                />
                <span className="span_menu" key="t-file">
                  Tienda
                </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/productos">
                    <span className="span_submenu">Todos los productos</span>
                  </Link>
                </li>
                <li>
                  <Link to="/vehiculos_lista/add_imagen">
                    <span className="span_submenu">Todos los vehículos</span>
                  </Link>
                </li>
                <li>
                  <Link
                    
                    to="#"
                  >
                    <span className="span_submenu" key="t-file">
                      Análisis
                    </span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/page/analytics/overview">
                        <span className="span_submenu">Resúmen</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#">
                        <span className="span_submenu">Productos</span>
                      </Link>
                    </li> */}
                  </ul>
                </li>
                {/* <li>
                  <Link to="#">Ingresos</Link>
                </li>
                <li>
                  <Link to="#">Pedidos</Link>
                </li>
                <li>
                  <Link to="#">Variaciones</Link>
                </li>
                <li>
                  <Link to="#">Categorías</Link>
                </li>
                <li>
                  <Link to="#">Cupones</Link>
                </li>
                <li>
                  <Link to="#">Impuestos</Link>
                </li>
                <li>
                  <Link to="#">Descargas</Link>
                </li>
                <li>
                  <Link to="#">Inventario</Link>
                </li>
                <li>
                  <Link to="#">Ajustes</Link>
                </li> */}
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
};

export default withRouter(withTranslation()(SidebarContent));
