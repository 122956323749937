import React, { createContext, useState, useContext, useEffect } from 'react';

// Crear el contexto del carrito
const CartContext = createContext();

// Crear un proveedor de contexto
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (producto) => {
    const existingProduct = cart.find(item => item.id === producto.id);
    if (existingProduct) {
      setCart(cart.map(item =>
        item.id === producto.id ? { ...item, stock: item.stock + producto.stock } : item
      ));
    } else {
      setCart([...cart, { ...producto, stock: producto.stock }]);
    }
  };

  const removeFromCart = (id) => {
    setCart(cart.filter(item => item.id !== id));
  };

  const incrementQuantity = (id) => {
    setCart(cart.map(item => 
      item.id === id ? { ...item, stock: item.stock + 1 } : item
    ));
  };
  
  const decrementQuantity = (id) => {
    setCart(cart.map(item => {
      if (item.id === id) {
        return { ...item, stock: Math.max(item.stock - 1, 1) }; // Asegura que la cantidad no baje de 1
      }
      return item;
    }));
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, incrementQuantity, decrementQuantity }}>
      {children}
    </CartContext.Provider>
  );
};

// Hook personalizado para usar el contexto del carrito
export const useCart = () => {
  return useContext(CartContext);
};
