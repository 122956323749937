import PropTypes from "prop-types";
import React, { useState } from "react";
import { TbArrowNarrowLeft, TbArrowNarrowRight  } from "react-icons/tb";
import './Menu.css'
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { TbHome } from "react-icons/tb";


// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo2 from '../../assets/images/logo-tc.png'
import logo3 from '../../assets/images/logo-symbol-tc.png'

// Redux Store
import {
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const Header = (props) => {
  const [arrowMenu, setArrowMenu] = useState(true)
  const [mouseEnt, setMouseEnt] = useState(false)

  const handleMouseEnt = () => {
    setMouseEnt(!mouseEnt);
  }

  const handleArrowMenu = () => {
    setArrowMenu(!arrowMenu)
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    /*
      set logic for changing sidebar
    */
    if (document.body.clientWidth >= 993) {
      //desktop view
      if (props.leftSideBarType === "default") {
        props.changeSidebarType("small");
      } else if (props.leftSideBarType === "small" || props.leftSideBarType === "compact") {
        props.changeSidebarType("default");
      }
    } else {
      //mobile view
      document.body.classList.toggle("sidebar-enable");
      props.changeSidebarType("default");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div
              style={{
                backgroundColor: "#19243f",
                borderBottom: "1px solid #19243f",
                transition: "all .3s ease",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
              className="navbar-brand-box"
            >
              <Link to="/dashboard" className="logo ">
                <span className="logo-sm">
                  <img
                    src={logo3}
                    alt=""
                    height="25"
                    style={{ filter: "drop-shadow(0 0 .4px white)" }}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={logo2}
                    alt=""
                    height="40"
                    width="120"
                    style={{ filter: "drop-shadow(0 0 .2px white)" }}
                  />
                </span>
              </Link>
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  border: "1px solid #ddd",
                  height: "max-content",
                  position: "absolute",
                  left: "-15px",
                }}
                type="button"
                onClick={() => {
                  tToggle();
                  handleArrowMenu();
                }}
                className=" button_menu"
                id="vertical-menu-btn"
              >
                {arrowMenu ? (
                  <TbArrowNarrowRight size={20} />
                ) : (
                  <TbArrowNarrowLeft size={20} />
                )}
              </button>
              <div onMouseEnter={() => setMouseEnt(true)} onMouseLeave={() => setMouseEnt(false)} className="contain_button_to_home">
                <Link to="/" className="content_button_to_home">
                  <TbHome size={22} />
                </Link>
                <span className={`${mouseEnt ? "active_to_home" : "home_span_oculto"}`}>
                  Home!
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <NotificationDropdown />
            <ProfileMenu />

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
  changeSidebarType,
})(Header);
