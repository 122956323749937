// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useEffect, useState } from 'react'
import "./styles/ModalVehiculoTire.css"
import VehiculoResult from './VehiculoResult';
import { TbArrowLeft } from 'react-icons/tb';
import { get } from '../../../store/actionsSinLogin/actions';


const ResultButtons = ({
  marcaUpdate,
  marca,
  yearUpdate,
  year,
  lineUpdate,
  line,
  versionUpdate,
  version,
}) => {
  const [markList, setMarkList] = useState({});
  const [selectedMark, setSelectedMark] = useState(null);
  const [yearList, setYearList] = useState({});
  const [selectedYear, setSelectedYear] = useState(null);
  const [lineList, setLineList] = useState({});
  const [selectedLine, setSelectedLine] = useState(null);
  const [versionList, setVersionList] = useState({});
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [vehicle, setVehicle] = useState(null);

  /// Funciones de Petición

  const getMark = () => {
    const url = "/vehiculo/marca";
    get(url)
      .then((response) => {
        setMarkList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getYear =  () => {
    if (selectedMark) {
      const url = `/vehiculo/year/get_year_marca/?marca=${selectedMark}`;
      get(url)
        .then((response) => {
          setYearList(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getLine = async () => {
    if (selectedYear) {
      const url = `/vehiculo/linea/get_linea_year/?year=${selectedYear}`;
      get(url)
        .then((response) => {
          setLineList(response.data);
        })
        .catch((error) => {
          console.error(error);
        }); 
    }
  };

  const getVersion = async () => {
    if (selectedLine) {
      const url = `/vehiculo/version/get_version_linea/?linea=${selectedLine}`;
      get(url)
        .then((response) => {
          setVersionList(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getVehicle = async () => {
    if (selectedVersion) {
      const url = `/vehiculo/vehiculo/get_vehiculo_version/?version=${selectedVersion}`;
      get(url)
        .then((response) => {
          setVehicle(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  /// EFECTOS MANEJADORES DE LOS INPUTS
  useEffect(() => {
    if (marca === null) {
      setSelectedMark(marca);
      setYearList({});
      setLineList({});
      setVersionList({});
      setVehicle(null);
      getMark();
    }
  }, [marca]);

  useEffect(() => {
    if (year === null) {
      setSelectedYear(year);
      setLineList({});
      setVersionList({});
      setVehicle(null);
      getYear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  useEffect(() => {
    if (line === null) {
      setSelectedLine(line);
      setVersionList({});
      setVehicle(null);
      getLine();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line]);

  useEffect(() => {
    if (version === null) {
      setSelectedVersion(version);
      setVehicle(null);
      getVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  /// Use Effect

  useEffect(() => {
    getMark();
  }, []);

  useEffect(() => {
    if (selectedMark) {
      getYear();
      marcaUpdate(selectedMark);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMark]);

  useEffect(() => {
    if (selectedYear) {
      getLine();
      yearUpdate(selectedYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  useEffect(() => {
    if (selectedLine) {
      getVersion();
      lineUpdate(selectedLine);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLine]);

  useEffect(() => {
    if (selectedVersion) {
      getVehicle();
      versionUpdate(selectedVersion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersion]);

  return (
    <section className="container_buttons">
      <div>
        <div className="content_button">
          {markList
            ? Array.isArray(markList)
              ? markList.map((item) => (
                  <button
                    className="button_result_modal"
                    key={item.id}
                    onClick={() => {
                      setSelectedMark(item.id);
                      setMarkList(null);
                    }}
                  >
                    {item.name}
                  </button>
                ))
              : []
            : null}
        </div>

        <div className="content_button">
          {yearList
            ? Array.isArray(yearList)
              ? yearList.map((item) => (
                  <button
                    className="button_result_modal"
                    key={item.id}
                    onClick={() => {
                      setYearList({});
                      setSelectedYear(item.id);
                    }}
                  >
                    {item.name}
                  </button>
                ))
              : []
            : null}
        </div>

        <div className="content_button">
          {lineList
            ? Array.isArray(lineList)
              ? lineList.map((item) => (
                  <button
                    className="button_result_modal"
                    key={item.id}
                    onClick={() => {
                      setSelectedLine(item.id);
                      setLineList(null);
                    }}
                  >
                    {item.name}
                  </button>
                ))
              : []
            : null}
        </div>

        <div className="content_button">
          {versionList
            ? Array.isArray(versionList)
              ? versionList.map((item) => (
                  <button
                    className="button_result_modal"
                    key={item.id}
                    onClick={() => {
                      setSelectedVersion(item.id);
                      setVersionList(null);
                    }}
                  >
                    {item.name}
                  </button>
                ))
              : []
            : null}
        </div>
        {vehicle
          ? vehicle.map((vehiculo) => (
              <VehiculoResult key={vehiculo.id} vehiculo={vehiculo} />
            ))
          : null}
      </div>
      <div className="container_button_volver">
        <div className="button_volver">
          <div className="icon_volver">
            <TbArrowLeft className="icon" size={26} />
          </div>
          <div>
            <span className="volver">Volver</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultButtons