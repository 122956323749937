import React, { useState } from 'react'
import NavbarTienda from '../HorizontalLayout/NavBarTienda'
import FooterHome from '../../pages/Home/components/FooterHome'
import logoWhatsapp from "../../assets/images/whatsapp-svgrepo-com.svg"
import "./ContainerTienda.css"

const ContainerTienda = ({children}) => {
  const [mouseEnt, setMouseEnt] = useState(false);

  return (
    <main>
      <div>
        <NavbarTienda />
      </div>
      <div>
        {children}
      </div>
      <div className='content_wsapp'>
        <span className={`${mouseEnt ? "p_wapp" : "p_wapp_oculto"}`}>¡Vamos a chatear!</span>
        <img onMouseEnter={() => setMouseEnt(true)} onMouseLeave={() => setMouseEnt(false)} className='img_wapp' src={logoWhatsapp} alt="" />
      </div>
      <div>
        <FooterHome />
      </div>
    </main>
  )
}

export default ContainerTienda